.pmdashboard-admin-ul {
    padding: 0;
    margin: 0;
  }
  
  .pmdashboard-admin-ul li {
    list-style: none;
  }
  
  ::-webkit-scrollbar {
    display: block;
    width: 5px;
    background: grey;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 30px;
    display: block;
  }
  
  ::-webkit-scrollbar-thumb {
    background: 333;
    border-radius: 30px;
    display: block;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: teal;
    display: block;
  }
  
  .pmdashboard-side-menu {
    position: fixed;
    background: #f4f5f7 !important;
    width: 20vw;
    height: 100%;
    transition: width 0.2s ease-in;
  }
  
  .pmdashboard-side-menu .pmdashboard-main-menu .menu-item {
    color: #333;
    text-decoration: none;
    font-size: 12px;
    display: block;
    font-weight: 600;
    cursor: pointer;
  }
  
  .pmdashboard-side-menu .pmdashboard-main-menu .menu-item.active {
    background: teal;
    border-radius: 5px;
    color: #fff;
  }
  
  .pmdashboard-side-menu .pmdashboard-main-menu {
    margin: 5px 0;
    position: relative;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .pmdashboard-side-menu .pmdashboard-main-menu .menu-item .menu-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 12px;
    line-height: 40px;
    text-align: center;
  }
  
  .pmdashboard-side-menu .pmdashboard-main-menu .menu-item span {
    position: absolute;
    display: inline-block;
    line-height: 40px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
  
  .pmdashboard-side-menu .pmdashboard-main-menu .sub-menu {
    color: #333;
    margin-left: 20px;
    border-left: 1px solid #666;
    box-sizing: border-box;
    padding-left: 30px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in;
  }
  
  .pmdashboard-side-menu .pmdashboard-main-menu .sub-menu.active {
    max-height: 200px;
    color: #999;
  }
  
  .pmdashboard-side-menu .pmdashboard-main-menu .sub-menu a {
    display: block;
    margin: 5px 0;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: #333;
    box-sizing: border-box;
    padding: 5px;
  }
  
  .pmdashboard-side-menu .pmdashboard-main-menu .sub-menu a.active {
    background: teal;
    display: block;
    border-radius: 5px;
    color: #fff !important;
    margin-right: 20px;
  }
  