
.main {
  background: url(../../../img//ChannelPartner/NewImg/shelf-plain-final.jpg)
    no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: inherit;
  width: 100% !important;
  height: 96vh;
  display: flex;
  padding-top: 174px;
  padding-right: 100px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0px !important;
}
header {
  position: absolute;
  top: 22px;
  left: 10rem;
  max-width: 1600px;
  width: 100%;
}
.navbar-brand {
  max-width: 14vw;
  width: 100%;
  height: auto;
}
.navbar-brand img {
  width: 10vw;
  height: 8vh;
}
.bookself {
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(6, 184px);
  grid-auto-rows: minmax(70px, auto);
  max-width: 1520px;
  width: 100%;
  position: relative;
  row-gap: 59px;
  column-gap: 78px;
  right: 0.4%;
}
.book-items {
  position: relative;
  cursor: default;
  padding: 2px 4px;
  margin: 0;
  display: grid;
  break-inside: avoid;
  width: 80px;
}
.floor-search {
  position: absolute;
  bottom: 9%;
  left: 19%;
  transform: unset;
  border-bottom: 40px solid transparent;
  border-left: 45px solid transparent;
  border-right: 45px solid transparent;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  max-height: 30px;
  max-width: 1024px;
  width: 100%;
}
.bot {
  position: absolute;
  bottom: 9%;
  right: 14%;
}
.bot img {
  max-width: 130px;
  width: 100%;
  height: auto;
}
.name-plate {
  margin: -60px 0 0px 12px;
  position: absolute;
}

.seprater {
  position: absolute;
  top: -1.3rem;
  right: -4.9rem;
}
.seprater img {
  max-width: 90px;
  width: 100%;
}
.navbar-nav .nav-item a {
  font-size: 24px;
}
.name-plate .d-text {
  font-size: 16px;
}
.button_container {
  max-width: 60px;
  width: 100%;
  height: auto;
  
}
.button_container img {
  width: 100%;
  height: 100%;
}

.button_container-header {
  max-width: 80px;
  width: 100%;
  height: auto;
}
.button_container-header img {
  width: 100%;
  height: 100%;
}

.channel-partner-search input {
  bottom: -3rem;
  font-size: 20px !important;
  height: 5rem;
  width: 56rem !important;
}
.channel-partner-search .clearsearch {
  top: -0.2rem !important;
  right: -16px !important;
}
.chatbot-container-channel-partner
  .rcw-close-widget-container
  > .rcw-launcher {
  bottom: 4vw !important;
  right: 1vw !important;
  /* bottom: 95px;
  right: 13px; */
  /* right: 13px; */
}
.chatbot-container-channel-partner .rcw-launcher .rcw-badge{
  margin-right: -1vh !important;
  margin-top: -5vh !important;
  position: absolute !important;
  line-height: 3vh;
}

.channel-partner-search .react-autosuggest__suggestions-container {
  width: 103% !important;
  position: absolute;
  left: 25px;
}

.channel-partner-search .react-autosuggest__suggestions-container {
  bottom: 5px;
}
.seprater-front {
  position: absolute;
  top: -1.3rem;
  right: 1.1rem;
  left: 0.3rem;
}

.seprater-column-thirdrow {
  position: absolute;
  top: 1.7rem;
  right: 1.1rem;
  left: 0.8rem;
}
.seprater-column-thirdrow img {
  max-width: 90px;
  width: 100%;
}
/* .seprater-column-specialCases {
      position: absolute;
      top: -1.3rem;
      right: 1.1rem;
      left: -0.2rem;
  } */
.seprater-column-specialCases img {
  max-width: 125px !important;
  margin-top: 0px;
  /* width: 100%; */
}
/* .seprater-front img{
          max-width: 90px;
          width: 100%;
      } */

.ChannelPartner-documentModal .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.ChannelPartner-documentModal .modal-header .close {
  padding: unset !important;
  margin: unset !important;
}

.ChannelPartner-documentModal .modal-header {
  height: 25px;
  margin-top: 0px;
  border-bottom: none !important;
  justify-content: end !important;
}

/* .ChannelPartner-documentModal-content {
          width: 78%;
        } */
.ChannelPartner-documentModal-content .pagination-document {
  margin-left: -51%;
}
.ChannelPartner-modal_close-row {
  position: absolute;
  margin-top: -0.7rem;
  right: -24%;
}
.documentlist-scroll {
  height: 55vh;
  overflow: hidden auto;
  width: 35vw !important;
}
.channelPartner-modal-fs {
  font-size: 1vw;
}
.carousal-column {
  margin-left: 5vw;
}
.seprater-intermediate-wrapper {
  position: absolute;
  /* left: 2.2%; */
  width: 100%;
  padding-left: 12%;
  padding-right: 10%;
}
.seprater-intermediate {
  position: relative;
  top: -1.3rem;
  right: -5.9vw;
}

.seprater-intermediate img {
  max-width: 90px;
  width: 100%;
}

.seprater-intermediate-wrapper-front {
  right: 42.1vw;
  justify-content: space-around !important;
  width: 55vw;
}

.seprater-intermediate-wrapper-end {
  right: -5.9vw;
  justify-content: space-around !important;
  width: 55vw;
}

.carousal-column-space-required-6 {
  margin-left: 30vw;
}
.name-plate-space-required-6 {
  margin-left: 30vw;
}

.seprater-column {
  right: 3.5vw;
}
.document-preview-iframe-wrapper {
  margin-left: -6%;
}
.document-preview-iframe {
  height: 80vh;
  width: 41vw;
}

#head {
  height: 68vh;
  width: 29vw;
  position: absolute;
  bottom: 3vh;
  left: 40%;
  transform: translateX(-50%);
 /*  background: url(../../../img/ChannelPartner/HeadSearch/NewImg/head-main.webp)
  no-repeat center; */
  background-size: cover;
  color: white;
  font-size: clamp(0.6rem, 1vw, 0.9rem);
}
#rack1 {
  /* position: absolute;
  width: 100%;
  max-width: 550px;
  width: 100%;
  top: 232px;
  left: 30px; */
}

#rack2 {
  position: absolute;
  max-width: 400px;
  width: 100%;
  bottom: 252px;
  left: 100px;
}
.CP-head-book1 {
  height: 60px;
  width: 50px;
}
/*book size*/
.CP-head-book-items {
  position: relative;
  cursor: default;
  padding: .1vw .2vw;
  margin: 0;
  display: grid;
  page-break-inside: avoid;
  break-inside: avoid;
  width: 4vw;
}
.channel-partner-head-search {
  z-index: 9;
  position: fixed;
  top: 13vh;
  margin-left: 16vw !important;
}

/* .CP-head-books-gap {
  margin-left: 18vw;
} */

.cp_searchhead-rack_1 {
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 16%;
  top: 1.5%;
  left: 80%;
}

.cp_searchhead-rack_2 {
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 16%;
  top: 25%;
  left: 49%;
  justify-content: center;
}

.cp_searchhead-rack_3 {
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 16%;
  bottom: 36%;
  left: 54%;
  justify-content: center;
}

.cp_searchhead-rack_4 {
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 16%;
  bottom: 3%;
  left: 80%;
}

.cp_searchhead_modal-h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2em;
  margin-bottom: 30px;
  margin-left: 24rem;
}
.cp_searchhead_modal-index-subsection {
  margin-left: 10vw;
  /* height: 459px;
  overflow-y: auto;
  margin-right: -90px; */
}
.cp_searchhead_modal-index-section {
 
}
.deltaHeadSearchModal .modal-body {
  background: url(/src/img/ChannelPartner/HeadSearch/documentModal-2.png)
    no-repeat center !important;
  width: 100vw;
  margin-left: -17vw;
}
.cp_searchhead_modal .pagination {
  margin-left: -12vw;
}
.cp_searchhead_modal-docs {
  max-height: 400px;
  overflow-y: auto;
  min-height: 375px;
  display: flex;
  align-items: center;
  padding-right: 5vw !important;
}
.CP-head-bmodal-body-col {

}

.CP-head-bmodal-body-col .pagination {
  margin-right: 13vw;
}
.cp_searchhead_modalwithoutlist-h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2em;
  margin-top: 275px;
  margin-left: 25rem;
}

.cp-searchhead-subcategory-list-onclick {
  text-decoration: underline !important;
  color: black !important;
}
.CP-head-Modal-MainContainer {
  
}
.cp_header {
  height: 10vh;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: white;
  max-width: 100% !important;
  left: 0 !important;
}
.CP-Head-searchIcon {
  position: absolute;
  top: 2vh !important;
  left: 2.25vh !important;
  font-size: 1.4vw;
}

.homepage-profile-avatar{
width: 7vh !important;
height: 7vh !important;
}

.homepage-profile-avatar.sb-avatar__text{
width: 7vh !important;
height: 7vh !important;
line-height: normal !important;
line-height: initial !important;
text-align: center !important;
color: rgb(255, 255, 255) !important;
border-radius: 100% !important;
margin-top: -0vh;
}

.owl-theme .owl-nav {
  margin-top: 10px;
  font-size: 27px;
  color: white;
}

.owl-carousel .owl-nav button.owl-next span {
  position: relative;
  top: -0.8vw;
  left: 0.1vw;
  font-size: 2vw;
}

.owl-carousel .owl-nav button.owl-prev span {
  position: relative;
  top: -0.8vw;
  left: 0vw;
  font-size: 2vw;
}

.CP-head-search input {
  height: 7vh;
  text-indent: 0vw;
  border: 0.1vw solid #d6d4d4;
  font-size: 1.5vw;
  padding-left: 3vw;
  width: 40vw;
  border-radius: 0.65vw !important;
}

.CP-head-search .fa-search {
  position: absolute;
  top: 10px;
  right: 61px;
  left: 12px;
width: fit-content;
}
.CP-Head-Search-Pagination {
width: 600px;
padding-top: 10px;
}
.CP-head-search-NoResult {
margin-left: 10%;
font-size: 25px;
margin-top: 18vh;
}

.CP-bookshelf-NoResult {
position: relative;
top: -32vh;
left: -118px;
font-size: 25px;

}

.ChannelPartner-documentModal .modal-body {
background: url(/src/img/ChannelPartner/NewImg/book-bg.webp)
  no-repeat 100%;
background-size: cover;
/* overflow-y: auto; */
height: 90vh;
width: 97vw !important;
margin: 0;
padding: 0;
}
.ChannelPartner-modal_close {
  float: right;
  width: 0.75vw !important;
  height: 2vh !important;
  padding: 0.35vw 0.35vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
  opacity: 1;
  border-radius: 0.25vw;
  box-sizing: content-box;
  margin-left: 5%;
}

.ChannelPartner-modal_close-row {
position: absolute;
margin-top: -0.7rem;
right: 1%;
}





.selfCarousal {
position: relative;
bottom: -0.3vh;
}


.CP-head-book-wrap {
/* column-gap: 0;
display: inline;
grid-template-columns: unset;
align-items: unset;
position: unset; */
width: 60px;
}

.cp_searchhead-rackbook {
  flex: 0.2 0 !important;
}
.highlight {
padding: .75vw 1vw;
border-left: .25vw solid #198754;
}
.cp-searchhead-subcategory-list:hover {
text-decoration: underline !important;
color: black;
}
.CP-Head-Search-Pagination .pagination{
width: 100%;
}

.CP_searchhead_modal-h1-less {
/* text-align: center; */
font-size: 4vh;
padding-top: 10vh;
margin-top: 9vh;
}

.CP_searchhead_modalwithoutlist-h1 {
text-align: center;
font-size: 5vh;   
margin-top: 9vh;
padding-top: 28vh;
}

.CP_searchhead_modal-h1 {
/* text-align: center; */
font-size: 4vh;
margin: 0 !important; 
margin-bottom: 3vh !important;
}

.cp-content-container .slick-slider {
position: absolute !important;
z-index: 2!important;
width: 18vw!important;
height: 21vh!important;
left: 40vw!important;
top: 56vh!important;
cursor: pointer!important;
overflow: hidden!important;
text-overflow: ellipsis!important;
}



.content-container .slick-slider {
position: absolute;
z-index: 2;
width: 14vw;
height: 17vh;
left: 46vw;
top: 64vh;
cursor: pointer;
overflow: hidden;
text-overflow: ellipsis
}
  

.cp_searchhead-main .owl-item{
width: 5vw !important;
}

.owl-stage{
transform: translate3d(-6vw, 0px, 0px);
}

.CP-head-book-image:hover {
transform: perspective(200px) rotateY(-20deg) translateX(2px) scaleX(0.94);
transform-style: preserve-3d;
box-shadow: 6px 6px 12px -1px rgba(0, 0, 0, 0.1),
  20px 14px 16px -6px rgba(0, 0, 0, 0.1);
}