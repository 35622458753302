

.delta-new-search-result-page-documentModal {
  margin: 0 !important;
}

.delta-new-search-result-page-documentModal .modal-body {
  background: url(/src/img/ChannelPartner/NewImg/book-bg.webp) no-repeat 100%;
  background-size: cover;
  /* overflow-y: auto; */
  height: 90vh;
  width: 100vw !important;
  margin: 0;
  /* margin-left: -29.5vw;
margin-right: -29.5vw; */
  /* padding-top: 10%; */
}
.delta-new-search-result-page-documentModal .modal-content {
  background-color: transparent !important;
  border: none !important;
  width: 100vw !important;
}
.delta-new-search-result-page-documentModal .modal-header .close {
  padding: unset !important;
  margin: unset !important;
}
.delta-new-search-result-page-documentModal .modal-header {
  height: 7vh;
  margin-top: 0px;
  border-bottom: none !important;
  justify-content: end !important;
  width: 100vw !important;
}

.delta-new-search-result-page-close-row {
  position: absolute;
  margin-top: 0.3vh;
  right: 6vw;
}
.delta-new-search-result-page-close {
  float: right;
  width: 1.5vw !important;
  height: 3.5vh;
  padding: 0.25vh 0.25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
  opacity: 0.7;
  border-radius: 1vh;
  box-sizing: content-box;
  margin-left: 1vw;
}
.delta-new-search-result-page-modal-body {
  width: 80vw !important;
  height: 70vh !important;
  margin-top: 5vh !important;
  margin-left: 9vw !important;
}

.delta-new-search-result-page-modal-h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2em;
  margin-bottom: 3vh;
  /* margin-left: 24rem; */
}
.delta-new-search-result-page-modal-index-section {
  /* margin-bottom: -173px; */
  /* margin-left: 9vw; */
  width: 36vw;
  height: 61vh;
  overflow-y: auto !important;
  overflow-x: hidden;
  padding-right: 6vw !important;
}

.briefcase-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 7vh;
  height: 7vh;
  margin-left: 1vw;
  background-color: #01a982;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visual effect */
  cursor: pointer;
  padding: 0 !important;
}

.SearchResult-documentModal .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.SearchResult-documentModal .modal-header .close {
  padding: unset !important;
  margin: unset !important;
}


.SearchResult-documentModal .modal-header {
  height: 25px;
  margin-top: 0px;
  border-bottom: none !important;
  justify-content: end !important;
}

/* .ChannelPartner-documentModal-content {
          width: 78%;
        } */
.SearchResult-documentModal-content .pagination-document {
  margin-left: -51%;
}

.ds_searchhead_modal-h1 {
  /* text-align: center; */
  font-size: 4vh;
  margin: 0 !important;
}
.ds_searchhead_modalwithoutlist-h1 {
  text-align: center;
  margin-top: 26vh;
  margin-left: -6vw !important;
  font-size: 5vh;
}

.ds_searchhead_modal-h1-less {
  /* text-align: center; */
  font-size: 4vh;
  margin: 0 !important;
  margin-top: 16vh !important;
  margin-bottom: 4vh !important;
 /*  margin-left: 7vw !important; */
}

.delta-new-search-page-highlight {
 /* margin-left: 5px; */
 padding: 2vh 0.8vw !important;
 border-left: 0.7vh solid #198754;
 padding-left: 15px;
 font-size: 2vh !important;
 margin:0.5vh 0!important;
 border-bottom: 0.1vh solid #dee2e6 !important;
}
.delta-new-search-page-no-highlight {
  margin:0.5vh 0!important;
  padding: 2vh 1vw !important;
  padding-left: 15px;
  font-size: 2vh !important;
  border-bottom: 0.1vh solid #dee2e6 !important;
}

  /* .ds_searchhead_modal-index-subsection {
 font-size: 2vh !important; 
  }*/

.delta-new-search-page-documentlist-scroll {
  height: 57vh;
  overflow: hidden auto;
  font-size: 2vh !important;
}

.delta-new-search-result-page-modal-body-ref {
  width: 82vw !important;
  height: 70vh !important;
  margin-top: 5vh;
  margin-left: 9vw;
}

.delta-new-search-result-page-express-view-button {
  font-size: 2vh !important;
  height: 5vh;
  border-width: 0.1vh;
  padding: 0 1vw !important;
  border-radius: 1.1vh !important;
}
.delta-new-search-page-content .designbuttton {
  display: block;
  background-color: white;
  border: 0.5vh solid #01a982 !important;
  width: 30.9vw;
  height: 37.4vh;
  position: absolute;
  top: 21.1vh;
  left: 35.5vw;
  z-index: 20;
  align-items: center;
  text-align: center;
  padding: 13vh;
}

.delta-new-search-result-page-documentModal .modal-body{
  padding: 0!important;
}

.delta-new-search-result-page-modal-body {
  padding: 0 !important;
}

/* .delta-new-search-result-page-documentModal div pt-2 pb-2 col-6 col   -> padding 0 !important */
.delta-new-search-result-page-modal-body-title-container{
  padding: 0 !important;
}
.delta-new-search-result-page-modal-body .DS-head-bmodal-body-col {
  padding: 0 !important
}

.DS-head-bmodal-body-col .DS-head-bmodal-body-col-header{
  padding: 0 !important;
  margin: 0 !important;
}

.DS-head-bmodal-body-col .DS-head-bmodal-body-col-header .input-wrapper{
  padding: 1vh 0 1vh 3vh !important;
}

.DS-head-bmodal-body-col .DS-head-bmodal-body-col-header .input-wrapper input{
  padding: 0.1vh 1.1vw !important;
  font-size: 2.5vh;
  height: 5vh !important;
  border: 0.1vh solid gray;
  margin: 0 !important;
  line-height: 2vh !important;
  width: 95% !important;
  /* padding: 0 !important; */
  border: none !important;
}

.DS-head-bmodal-body-col .DS-head-bmodal-body-col-header .reset-wrapper{
  margin: 1.6vh 0 !important;
  padding: 0 !important;
}

.DS-head-bmodal-body-col .DS-head-bmodal-body-col-header .reset-wrapper .reset-btn{
  font-size: 2vh !important;
}

.delta-new-search-page-documentlist-scroll  .border-bottom a.fa{
  font-size: 3.5vh;
  /* padding: 0 !important;
  padding-left: 1.3vw !important; */
}

.delta-new-search-page-documentlist-scroll .fontx9 {
  font-size: 1.4vh !important;
  margin: 0 0 !important;
  margin-right: 0.6vw !important;
  border-radius: 1.5vh;
  padding: 0.45vh !important;
}
/* .border-bottom  */

.delta-new-search-page-documentlist-scroll  .border-bottom svg{
  height: 1.9vh;
  width: 1.9vh;
}

.delta-new-search-page-documentlist-scroll  .border-bottom{
  border-bottom: 0.4vh solid #dee2e6 !important;
  margin: 1vh !important;
}

.margin-padding-zero{
  margin: 0 !important;
  padding: 0 !important;
  /* width: 100%;
  height: fit-content;
  padding: 0px 1% !important */
}

.delta-new-search-page-documentlist-scroll .border-bottom .RatingApp{
  right: 0.5vw !important;
}

.delta-new-Search-page-Pagination .pagination{
  margin: 0.1vh 0 !important;
}
.delta-new-Search-page-Pagination .pagination a{
  margin: 0 !important;
  padding: 2.1vh 1vw !important;
  border: 0.05vh solid #ddd;
}
.delta-new-seearch-page-sku .table-td,tr{
  padding: 0.5vh 0.5vw !important;
  border: 0.1vh solid #DEE2E6 !important
}

.custom-feedbackNote tr{
  border: none !important;
}

.delta-new-seearch-page-sku th{
  padding: 0.5vh 0.5vw !important;
  border: 0.1vh solid #DEE2E6 !important
}
.delta-new-search-result-page-modal-body .pagination{
  margin: 0.1vh 0 !important;
}
.delta-new-search-result-page-modal-body  .pagination a{
  margin: 0 !important;
  padding: 2.1vh 1vw !important;
  border: 0.05vh solid #ddd;
}

.delta-new-search-result-page-modal-body-ref .DS-head-bmodal-body-col{
  padding: 0 !important;
}
.delta-new-search-result-page-modal-body-ref .pagination{
  margin: 0.1vh 0 !important;
  font-size: 2.2vh;
}
.delta-new-search-result-page-modal-body-ref .pagination a{
  margin: 0 !important;
  padding: 2.1vh 1vw !important;
  border: 0.05vh solid #ddd;
}
.delta-new-search-result-page-modal-body-ref .delta-new-search-page-documentlist-scroll {
  margin: 0 !important;
}

.delta-new-search-pagination-poc-list .pagination{
  margin: 0.1vh 0 !important;
  font-size: 2.2vh;
}
.delta-new-search-pagination-poc-list .pagination a{
  margin: 0 !important;
  padding: 2.1vh 1vw !important;
  border: 0.05vh solid #ddd;
}

.delta-new-search-result-page-modal-body-ref .DS-head-bmodal-body-col-header .input-wrapper {
  padding: 1vh 3vh !important;
  margin: 0 !important;
}

.delta-new-search-result-page-modal-body-ref  {
  padding: 0 !important;
  width: 80vw !important;
  height: 70vh !important;
  margin-top: 5vh;
  margin-left: 11vw;
}

.ds_searchhead_modal-index-subsection .border-bottom + ul{
  padding-left:2.7vw !important;
  margin: 0 !important;
}

.ds_searchhead_modal-h1 .fa.fa-download {
  font-size: 3.5vh !important;
  margin-left: 2vw !important;
  margin-top: -1vw !important;
}

.ds_searchhead_modal-h1 .custom-insight-download {
  font-size: 2vh;
  margin-top: 2vh;
  margin-left: 0;
}
.DS-head-bmodal-body-col .customScroll{
  margin: 1.1vh 2.1vw !important;
  height :60vh !important;
  max-height: none;
  overflow: auto !important;
}
.DS-head-bmodal-body-col .lnht12x {
  line-height: 2vh !important;
}
.DS-head-bmodal-body-col .customScroll .border-bottom{
  border-bottom: 0.2vh solid #dee2e6 !important;
  margin: 2vh 0 !important;
  
}

.DS-head-bmodal-body-col .visio-icon {
  height: 4vh;
  width: 3vh;
}

.DS-head-bmodal-body-col .briefcase_header {
  padding: 0 !important;
  margin: 0.6vh 1vw !important;
  border-bottom: 0.7vh solid #01a982;
}

.DS-head-bmodal-body-col  input.search-boxes {
  width: 24vw  !important;
  padding: 0 1vw !important;
  border: 0.1vh solid #606060 !important;
  height: 5vh !important;
  border-radius: 0px !important;
}


.DS-head-bmodal-body-col  .briefcase_note {
  padding: 1vh 1vw !important;
  margin: 1vh 0 !important;
  background-color: #333333;
  color: #ffffff;
}

.DS-head-bmodal-body-col .fa.fa-external-link-square-alt{
  font-size: 3vh !important;
}

.DS-head-bmodal-body-col #expressView .briefcase_header {
  margin: 0 !important;
}

.DS-head-bmodal-body-col #expressView  svg{
  height: 2.9vh;
  width: 2.9vh;
}

.DS-head-bmodal-body-col #expressView .delta-new-search-page-express_files_view   a.fa{
  margin: 0 0.2vw !important;
  padding: 0 !important;
  font-size: 3vh !important;
}

.DS-head-bmodal-body-col #expressView .delta-new-search-page-express_files_view   .form-check{
  /* margin: 0 0.2vw !important;
  padding: 0 !important;
  font-size: 3vh !important; */
  min-height: fit-content !important;
}

.DS-head-bmodal-body-col #expressView .delta-new-search-page-express_files_view  .custom-checkbox {
  top: 9%;
}
.DS-head-bmodal-body-col #expressView .delta-new-search-page-express_files_view .custom-checkbox-text {
  margin-left: 6.5%;
}
.DS-head-bmodal-body-col #expressView .delta-new-search-page-express_files_view .custom-checkbox-container {
  margin-bottom: 0.5vh;
}
.custom-check-box-disabled{
  opacity: 0.2 !important;
}
.delta-new-search-page-bmodal-body-col.new-sku-body{
  padding: 0 !important;
}
.delta-new-Search-page-Pagination{
  margin-top: 3vh !important;
  margin-left: 5vw !important;
}

/* .custom-modal-css {
.delta-new-search-nodocument {
  font-size: 20px;
  font-weight: 700;
}
.custom-modal-css {
  display: block;
  top: 22%;
  left: 1%;
} */

.custom-modal-css {
  display: block;
  top: 21vh;
  left: 35.5vw;
border-radius: 0 !important;
}

.custom-modal-body-css {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.custom-modal-body-css {
  width: 30.5vw;
  height: 30.5vh;
}
.custom-modal-css-title {
  font-size: 3vh !important;
}

.custom-padding-margin {
  margin: 0 !important;
  padding: 0 !important;
  padding: 0 1vw !important;
}

.custom-modal-button {
  width: 30%;
    font-size: 2.3vh !important;
    padding: 0% 3% !important;
    margin: 0 0.5vw !important;
    border-radius: 0 !important;
    height: 5.5vh;
}

.custom-modal-css .modal-content {
  width: fit-content;
  height: fit-content;
  margin: 0px;
  border-radius: 0 !important;
  margin: 0 !important;
  border: 0.1vh solid rgb(1, 169, 130) !important;
}

.custom-modal-css .modal-dialog{
  margin: 0  !important;
}
 
.custom-modal-css  .modal-dialog-border {
  border: 0.5vh solid rgb(1, 169, 130) !important;
  font-size: 2.2vh !important;
}
