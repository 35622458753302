:root {
  --main: #01a982;
  --text-light: #555555;
  --text-dark: #333333;
  --hover: #0000000a;
  --text-disable: #bbbbbb;
  --border-lite: #0000001f;
  --white: #fff;
  --focus: #17eba0;
  --background-shade: #f7f7f7;
  --background-black: #1c1c1c;
}

.pm-dashboard_header-text {
  width: 70%;
  text-align: center;
}

.pm-dashboard_homemain {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.pm-dashboard_sidebar {
  background: transparent;
  border-right: 1px solid #0000001f;
  height: 82.5vh;
  overflow-y: auto;
  padding: 10px;
}

.pm-dashboard_sidebar::-webkit-scrollbar {
  width: 6px;
}

.pm-dashboard_sidebar::-webkit-scrollbar-track {
  background: var(--background-shade);
  border-radius: 14px;
}

.pm-dashboard_sidebar::-webkit-scrollbar-thumb {
  background: var(--main);
  border-radius: 14px;
}

@supports not selector(::-webkit-scrollbar) {
  .pm-dashboard_sidebar {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

.pm-dashboard_profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  padding-top: 0;
}
.pm-dashboard_profile h2 {
  font-weight: inherit;
  color: var(--text-dark);
  margin-bottom: 6px;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
}

.pm-dashboard_profile-avatar {
  width: 96px;
  height: 96px;
}

.pm-dashboard_nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pm-dashboard_nav-menu ul li a {
  padding: 8.5px;
  font-size: 1vw;
  color: var(--text-light);
  margin: 8px auto;
  cursor: pointer;
  border: 1px solid var(--border-lite);
  border-radius: 6px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.pm-dashboard_nav-menu ul li img {
  width: 20px;
  margin-right: 6px;
}

.pm-dashboard_nav-menu ul li:hover {
  background-color: var(--hover);
}

.pm-dashboard_nav-menu ul li a.active {
  background-color: var(--hover);
  color: var(--text-dark);
}

.pm-dashboard_nav-menu ul li a {
  text-decoration: none;
}

.pm-dashboard_menu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pm-dashboard_bi-dashboard {
  width: 100%;
}

.pm-dashboard_bi-dashboard a {
  display: block;
  padding: 6px 12px;
  font-size: 1vw !important;
  color: var(--text-dark);
  font-weight: 700;
  background: transparent;
  border-radius: 8px;
  text-decoration: underline;
  transition: 0.3s ease;
  width: 100%;
  text-align: center;
}

.pm-dashboard_main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.pm-dashboard_charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  gap: 20px;
}

.pm-dashboard_chart-container {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--border-lite);
}

.pm-dashboard_content {
  flex-grow: 1;
  background: white;
  margin: 10px;
  border-radius: 8px;
  min-height: 231px;
  overflow-y: auto;
  overflow-x: hidden;
}

.pm-dashboard_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--hover);
  color: var(--text-dark);
  padding: 12px 20px;
}
.pm-dashboard_footer .menu {
  list-style: none;
  padding: 0;
  display: flex;
}
.pm-dashboard_footer .menu li {
  margin-left: 15px;
}
.pm-dashboard_footer .menu li a {
  color: var(--text-lite);
  text-decoration: none;
}
.pm-dashboard_footer .menu li a:hover {
  text-decoration: underline;
}
/* Diar pagination css*/
.pm-dashboard_pagination .page-item .page-link {
  background: none !important;
  border: none !important;
  color: black !important;
  font-weight: bold;
}
.pm-dashboard_pagination .active-page .page-link {
  background: lightgray !important;
  color: black !important;
  border-radius: 50%;
  font-weight: bold;
}
.pm-dashboard_pagination .page-item .page-link:hover {
  background: none !important;
  color: black !important;
}
/* Diar Modal css*/
.pm-dashboard_tabs .nav-link {
  padding: 12px 20px;
    text-align: center;
    border: none;
    border-radius: 0px !important;
    background-color: transparent;
    cursor: pointer;
    font-size: 1rem;
    color: #555;
    transition: color 0.3s;
    border-radius: 30px;
}

/* .pm-dashboard_tabs .nav-link.active {
  border: none;
  border-bottom: 3px solid #6750a4 !important;
  font-weight: bold;
  color: #6750a4 !important;
  background-color: white;
} */

.pm-dashboard_tab-container .nav-underline {
  gap: 0 !important;
}

.modal-header {
  border-bottom: none;
}

.table-row {
  background-color: transparent !important;
  color: black !important;
  border-bottom: 1px solid black !important;
}
/* diar add new */
.pm-dashboard_addnew {
  border-radius: 50px;
  border: 2px solid #01a982;
  background-color: transparent;
  color: black;
}
/* Diar scroll */
.diarTableFull {
  font-size: 11px;
  height: 63vh;
  overflow-y: auto;
  width: 80vw;
}

.diar_Fix_column_0 {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5 !important;
  left: 0 !important;
}

.diar_Fix_column_1 {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5 !important;
  left: 6vw !important;
  background-color: white !important;
}

.diar_Fix_column_2 {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5 !important;
  left: 11vw !important;
  background-color: white !important;
}

.diar_Fix_column_3 {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5 !important;
  left: 2vw !important;
  background-color: white !important;
}
.header_text {
  white-space: nowrap;
  padding: 10px 15px;
  text-align: left;
  width: fit-content !important;
  hyphens: none;
}

.pm-dashboard_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--hover);
  color: var(--text-dark);
  padding: 12px 20px;
  z-index: 99 !important;
  font-size: 1.2vw;
}
.pm-dashboard_footer .menu {
  list-style: none;
  padding: 0;
  display: flex;
  margin-bottom: 0.5rem;
}
.pm-dashboard_footer .menu li {
  margin-left: 15px;
}
.pm-dashboard_footer .menu li a {
  color: var(--text-lite);
  text-decoration: none;
}
.pm-dashboard_footer .menu li a:hover {
  text-decoration: underline;
}

.pm-dashboard_tab-container {
  /* max-width: 56vw;
    max-height: 55vh;
    height: fit-content;
    overflow-y: hidden;
    width: 100%;
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px; */
}
.pm-dashboard_tab-container .modal-content {
  /* width: 80vw !important;
  left: -20vw !important; */
}

.pm-dashboard_component-container {
  max-height: 51vh;
  height: fit-content;
  /* height: 45vh; */
  overflow-y: auto;
  overflow-x: hidden;
}
.pm-dashboard_form_control:focus {
  border-color: var(--focus) !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(23, 235, 161, 0.192) !important;
}

.pm-dashboard_tab-container .pm_dashboard_Project_master_tabs .nav-link.active {
  /* color: black !important;
border-bottom: 2px solid #00a982 !important; */
  border-bottom: 4px solid #6750a4 !important;
  color: #6750a4 !important;
  background-color: white;

  /* border-color: unset !important; */
}

.pm-dashboard_tab-container .pm_dashboard_Project_master_tabs .nav-link {
  color: black;
  background-color: #e1f8ff !important;
  transition:
    background-color 0.3s,
    color 0.3s;
  /* border-bottom: 2px solid #00a982 !important;  */

  /* border-color: unset !important; */
}

.pm-dashboard_tab-container
  .pm_dashboard_Project_master_tabs
  .nav-link.disabled,
.nav-link:disabled {
  color: #212529bf !important;
  cursor: default;
  pointer-events: none;
}

.pm-dashboard_tab-container .nav-underline {
  --bs-nav-underline-border-width: 0 !important;
}

.pm-dashboard_button {
  background: transparent;
  color: var(--text-dark);
  font-weight: 700;
  font-size: 2.8vh !important;
  border: none;
}
.pm-dashboard_button:hover {
  background-color: var(--hover);
}
.pm-dashboard_button:disabled {
  color: var(--text-disable);
  cursor: not-allowed;
}
.pm-dashboard_table-ViewAll {
  font-size: 11px;
  /* min-height: fit-content; */
  height: 63vh;
  overflow-y: auto;
  width: 80vw;
  overflow-x: auto;
}

.pm-dashboard_Fix_column_0 {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5 !important;
  left: 0 !important;
}

.pm-dashboard_Fix_column_1 {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5 !important;
  /* left: 5vw !important; */
}

.pm-dashboard_Fix_column_2 {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5 !important;
  /* left: 10vw !important; */
}
.pm-dashboard_Fix_column_3 {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5 !important;
  /* left: 12.5vw !important; */
}
.pm-dashboard_Fix_column_4 {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5 !important;
  /* left: 12.5vw !important; */
}
.pm-dashboard_table-row th {
  background-color: white !important;
  color: black !important;
  border-bottom: 1px solid black !important;
}

/* reviewTracker css*/

.pm-dashboard_form-container_reviewTracker {
  background: white;
  border-radius: 8px;
  /* width: 100%; */
}

.table_header {
  background-color: white !important;
  color: black !important;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 6px 0;
  /* border-top: 1px solid var(--border-lite); */
}

.addNewButton {
  border: 2px solid green;
  background-color: white;
  color: black;
  border-radius: 50px;
}

.deleteButton {
  border: 2px solid red;
  background-color: white;
  color: black;
  border-radius: 50px;
}
.title {
  width: 100%;
  padding: 6px 0;
}

.file-input {
  border: none;
}

.form-text {
  font-size: 1.2vw !important;
  color: black !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.form-text-custom {
  /* font-size: 1.2vw !important; */
  color: black !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.reviewTracker_textarea {
  min-height: 6vh;
  height: 10vh;
  max-height: 20vh;
}

.projectIdInput {
  background-color: white !important;
}

.pm-dashboard_reviewTracker_table-ViewAll {
  font-size: 11px;
  height: 32vh;
  overflow-y: auto;
  width: 80vw;
}

/* reviewTracker pagination css*/
.pm-dashboard_pagination .page-item .page-link {
  background: none !important;
  border: none !important;
  color: black !important;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
}

.pm-dashboard_pagination .active-page .page-link {
  background: lightgray !important;
  color: black !important;
  border-radius: 50%;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
}

.pm-dashboard_pagination .page-item .page-link:hover {
  background: none !important;
  color: black !important;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
}
.pm-dashboard_addnew {
  border-radius: 50px;
  border: 2px solid #01a982;
  background-color: transparent;
  color: black;
}
::-webkit-scrollbar {
  height: 8px; /* height of horizontal scrollbar ← You're missing this */
}

.pm-dashboard_reviewTracker_table-ViewAll {
  font-size: 11px;
  height: 68vh;
  overflow-y: auto;
  width: 80vw;
}
/* reviewTracker css*/

.pm-dashboard_profile-pic {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

#reviewTrackerForm #files::-webkit-file-upload-button {
  /* visibility: hidden; */
  border: 0.1vh solid;
  padding: 1% 10% !important;
  margin: 5% !important;
  margin-right: 15% !important;
  /* height: 1vh; */
}

/* reviewTracker form css*/
.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.form-column {
  flex: 1 1 calc(50% - 1rem);
  min-width: 200px;
}

.reviewTracker_modalContent .modal-content {
  width: 80vw !important;
  left: -20vw !important;
}

.reviewTracker-filter-dropdown-container {
  position: relative;
  /* display: inline-block; */
  z-index: 1050;
  max-height: 4vh;
}

.pm-dashboard_Fix_column_2 a {
  color: #0d6efd !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}
.PM_Dashboard_Submilestone a {
  color: #0d6efd !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.blue-link {
  color: blue !important;
  text-decoration: underline;
}

.reviewTracker-filter-dropdown {
  width: 26vw;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: fixed;
  z-index: 99;
}

.filter-dropdown-div {
  display: flex;
  gap: 10px;
}

.dropdown-container {
  width: 12vw;
  min-width: 12vw;
  max-width: 12vw;
  padding: 5px;
}

.dropdown-container .custom-select__control {
  font-size: 11px !important;
  width: 100% !important;
  max-width: 100% !important;
  max-height: 8vh !important;
  height: 8vh !important;
}

.dropdown-container .custom-select__option {
  font-size: 11px !important;
}

.dropdown-container .custom-select__value-container {
  max-height: 7vh !important;
  height: 7vh !important;
  overflow-y: auto !important;
}
.pm-dashboard_table-ViewAll th {
  white-space: nowrap;
}
.pm-dashboard_table-ViewAll td {
  white-space: nowrap;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
.pm-dashboard_Fix_column_4 a {
  color: #0d6efd !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}
.PM_Dashboard_Submilestone a {
  color: #0d6efd !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}
.pm-dashboard_Fix_column_4 {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 5 !important;
  /* left: 12.5vw !important; */
}

.form-text .text-danger {
  font-size: 14px !important;
  color: red !important;
}
.text-muted.form-text {
  color: #6c757d !important;
  font-size: 14px !important;
}
.form-text-custom .text-danger {
  font-size: 14px !important;
  color: red !important;
}
.text-muted.form-text-custom {
  color: #6c757d !important;
  font-size: 14px !important;
}

/* .pas-main-tag { */
.PM_Dashboard_main-tag {
  .PM_Dashboard_main-tag {
    margin-left: 310px;
    margin-top: 10px;
    position: fixed;
    width: calc(100vw - 340px) !important;
  }  
.text-muted.form-text{
color:#6c757d !important;
font-size: 14px !important;
}
  
.pas-main-tag {
  margin-left: 310px;
  margin-top: 10px;
  position: fixed;
  width: calc(100vw - 340px) !important;
}
/* } */

/* Roles Table */
.roles-admin-tr th {
  background-color: teal !important;
  color: #fff !important;
}
}  
.PM_Dashboard_table-container {
  height: calc(100vh - 250px);
  overflow-y: auto;
  width: 78%;
  margin-left: auto;
}

.PM_Dashboard-tr th {
  background-color: teal !important;
  color: #fff !important;
  font-size: 12px !important;
  padding: 0.5vh 0.25vw !important;
}
.text-muted.form-text {
  color: #6c757d !important;
  font-size: 14px !important;
}
.text-muted.form-text-custom {
  color: #6c757d !important;
  font-size: 14px !important;
}

.reviewTracker-toggleButton {
  color: blue;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 0.1vw;
}

/* Roles Table */
.roles-admin-tr th {
  background-color: teal !important;
  color: #fff !important;
}

.pm-dashboard_header-icon ul{
  list-style: none;
}
.pm-dashboard_header-icon ul li {
  display: inline-block;
  margin-left: 10px;
}
.pm-dashboard_header-icon ul li a img {
  width: 16px;
}

.pm-dashboard_header_admin {
  width: 20px;
  cursor: pointer;
}
/* All modules input formcontrol css */
.formInput {
  height: 6.5vh !important;
}

.custom-file-input {
  height: 6.5vh !important;
}

.custom-file-input::-webkit-file-upload-button {
  height: 6.5vh;
  line-height: 6.5vh;
  padding-top: 0 !important;
  /* padding-left: 3.5vh !important; */
  border: none;
  /* background-color:rgb(75, 160, 75); */
  color: black;
  /* cursor: pointer; */
  margin-top: 0.01vh;
  align-items: center;
  /* font-size: 2vh !important; */
}

.margin-padding-zero .form-control {
  padding: 0 !important;
  font-size: 2vh !important;
  padding-left: 1vh !important;
}

.pm-dashboard_header_feedback {
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.pm-dashboard_feedback-table {
  width: max-content;
}

.pm-dashboard_feedback-table-tr {
  font-size: 12px;
}
.PM_Dashboard_table-container td {
  font-size: 12px !important;
}

.PM_Dashboard_User_form .form-control {
  height: 39.3333px !important;
}
.PM_Dashboard_RAG_Value {
  border-radius: 20px !important;
  text-align: center !important;
  color: white !important;
  /* margin-bottom:5px !important; */
  padding: 7px 10px !important;
  display: inline-block !important;
  min-width: 60px !important;
  line-height: 1 !important;
  font-size: 15px !important;
}
.PM_Dashboard_Status_Value {
  border-radius: 20px !important;
  text-align: center !important;
  /* color: white !important; */
  /* margin-bottom:5px !important; */
  padding: 7px 10px !important;
  display: inline-block !important;
  min-width: 60px !important;
  line-height: 1 !important;
  font-size: 15px !important;
}

.PM_Dashboard_RAG_Value_Table {
  border-radius: 20px !important;
  text-align: center !important;
  text-justify: distribute;
  color: black !important;
  padding: 0px !important;
  width: 40px !important;
  height: 20px;
  padding-top: 2px !important;
}
.PM_Dashboard_Status_Value_Table {
  border-radius: 20px !important;
  text-align: center !important;
  color: black !important;
  width: auto !important;
  min-width: 40px !important;
  height: 20px !important;
  padding: 0px 6px !important;
  font-size: 12px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.PM_Dashboard_Status_Value_Table_Custom {
  border-radius: 20px !important;
  text-align: center !important;
  /* color: white !important; */
  width: auto !important;
  min-width: 40px !important;
  height: 20px !important;
  padding: 0px 6px !important;
  font-size: 12px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.PM_Dashboard_Wrap_Text{
  white-space: normal !important;
  /* width: 5vw !important; */
}

.title {
  width: 100%;
  padding: 14px 0;
}
.title p {
  font-size: 0.865rem;
  color: var(--text-light);
}
.title h5 {
  text-align: start;
  color: var(--text-dark);
  font-size: 1rem;
  font-weight: 600;
}

.input-container {
  position: relative;
}

.input-field {
  width: 100%;
  padding: 10px 5px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid var(--border-lite);
  outline: none;
  background: transparent;
  transition: border-color 0.3s;
}

.input-field-custom {
  width: 100%;
  padding: 10px 5px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid var(--border-lite);
  outline: none;
  background: transparent;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-bottom: 2px solid #6200ea;
}

.input-field-custom:focus {
  border-bottom: 2px solid #6200ea;
}

.label {
  position: absolute;
  left: 5px;
  top: 10px;
  font-size: 16px;
  color: gray;
  transition: 0.3s ease-out;
  pointer-events: none;
}

.input-field:focus ~ .label,
.input-field:not(:placeholder-shown) ~ .label {
  top: -10px;
  left: 5px;
  font-size: 12px;
  color: #6200ea;
}

.input-field-custom:focus ~ .label,
.input-field-custom:not(:placeholder-shown) ~ .label {
  top: -10px;
  left: 5px;
  font-size: 12px;
  color: #6200ea;
}

#hiddenDiv {
  display: none;
}

.md-form {
  position: relative;
}

.md-form select {
  display: block;
  width: 100%;
  padding: 12px 5px;
  border: none;
  border-bottom: 2px solid var(--border-lite);
  background: transparent;
  outline: none;
  font-size: 16px;
}

.md-form label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--text-light);
  pointer-events: none;
  transition: 0.3s ease-out;
}

.md-form select:focus + label,
.md-form select:valid + label {
  top: -10px;
  font-size: 12px;
  color: #6200ea;
}

.md-form select:focus {
  border-bottom: 2px solid #6200ea;
}

.md-form select:focus + label {
  color: #6200ea;
}

.md-form select:valid {
  border-bottom: 2px solid #6200ea;
}

.date-form-group {
  position: relative;
}

.date-form-control {
  border: none;
  border-bottom: 2px solid var(--border-lite);
  border-radius: 0;
  padding: 10px 0;
  width: 100%;
  outline: none;
  background: transparent;
}

.date-form-control:focus {
  border-bottom: 2px solid #6200ee;
  box-shadow: none;
}

.date-form-label {
  position: absolute;
  top: 10px;
  left: 0;
  pointer-events: none;
  transition: 0.2s ease all;
  color: #999;
}

.date-form-control:focus + .date-form-label,
.date-form-control:not(:placeholder-shown) + .date-form-label {
  top: -15px;
  font-size: 12px;
  color: #6200ee;
}

.input-group-append {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.PM_Dashboard_date_icon {
  content: "\f133";
}

.PM_Dashboard_Form_Control {
  visibility: hidden !important;
  position: absolute !important;
  height: 0px !important;
  width: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.title{
  width: 100%;
  padding: 14px 0;
}
.title p{
  font-size: 0.865rem;
  color: var(--text-light);
}
.title h5 {
  text-align: start;
  color: var(--text-dark);
  font-size: 1rem;
  font-weight: 600;
}

.input-container {
  position: relative;
}

.input-field {
  width: 100%;
  padding: 10px 5px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid var(--border-lite);
  outline: none;
  background: transparent;
  transition: border-color 0.3s;
}

.input-field-custom {
  width: 100%;
  padding: 10px 5px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid var(--border-lite);
  outline: none;
  background: transparent;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-bottom: 2px solid #6200ea;
}

.input-field-custom:focus {
  border-bottom: 2px solid #6200ea;
}

.label {
  position: absolute;
  left: 5px;
  top: 10px;
  font-size: 16px;
  color: gray;
  transition: 0.3s ease-out;
  pointer-events: none;
}

.input-field:focus ~ .label,
.input-field:not(:placeholder-shown) ~ .label {
  top: -10px;
  left: 5px;
  font-size: 12px;
  color: #6200ea;
}

.input-field-custom:focus ~ .label,
.input-field-custom:not(:placeholder-shown) ~ .label {
  top: -20px;
  left: 5px;
  font-size: 12px;
  color: #6200ea;
}

#hiddenDiv {
  display: none;
}
.navigation-button{
  padding: 8px 16px;
  border: none;
  background-color: #6750A4;
  color: var(--white);
  border-radius: 30px;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 0.3s;
  font-size: 18px !important;
}
.navigation-button:hover,
.navigation-button:disabled,
.navigation-button:focus {
  background-color: #6750A4;
  font-size: 18px !important;
}


.md-form {
  position: relative;
}

.md-form select {
  display: block;
  width: 100%;
  padding: 12px 5px;
  border: none;
  border-bottom: 2px solid var(--border-lite);
  background: transparent;
  outline: none;
  font-size: 16px;
}

.md-form label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--text-light);
  pointer-events: none;
  transition: 0.3s ease-out;
}

.md-form select:focus + label,
.md-form select:disabled + label,
.md-form select:valid + label {
  top: -10px;
  font-size: 12px;
  color: #6200ea;
}

/* .md-form select:disabled + label,
.md-form select:not(:placeholder-shown) + label {
  top: -10px;
  font-size: 12px;
  color: #6200ea;
} */

.md-form select:focus {
  border-bottom: 2px solid #6200ea;
}

.md-form select:focus + label {
  color: #6200ea;
}

.md-form select:valid {
  border-bottom: 2px solid #6200ea;
}

.date-form-group {
  position: relative;
}

.date-form-control {
  border: none;
  border-bottom: 2px solid var(--border-lite);
  border-radius: 0;
  padding: 10px 0;
  width: 100%;
  outline: none;
  background: transparent;
}

.date-form-control:focus {
  border-bottom: 2px solid #6200ee;
  box-shadow: none;
}

.date-form-label {
  position: absolute;
  top: 10px;
  left: 0;
  pointer-events: none;
  transition: 0.2s ease all;
  color: #999;
}

.date-form-control:focus + .date-form-label,
.date-form-control:not(:placeholder-shown) + .date-form-label {
  top: -15px;
  font-size: 12px;
  color: #6200ee;
}

.input-group-append {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.input-group-text {
  border: none;
  background: transparent;
  padding: 0;
}
/* File input */

.textfield {
  position: relative;
}

.textfield label {
  position: absolute;
  top: 30px;
  left: 1px;
  transform: translateY(-50%);
  color: var(--text-light);
  font-size: 16px;
  pointer-events: none;
  transition: 0.2s ease all;
}

.textfield textarea {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid var(--border-lite);
  border-radius: 0;
  outline: none;
  transition: 0.2s ease all;
}

.textfield textarea:focus {
  border-color: #6200ea;
}

.textfield textarea:focus + label,
.textfield textarea:not(:placeholder-shown) + label {
  top: 5px;
  font-size: 12px;
  color: #6200ea;
  bottom: 4vh;
}

.char-count {
  position: absolute;
  bottom: -20px;
  right: 10px;
  font-size: 12px;
  color: #999;
}

.char-count span {
  color: #6200ea;
}

.file-input-container {
  position: relative;
  margin: 5px auto;
  margin-top: 0px !important;
}

.file-input-container input[type="file"] {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  opacity: 0; /* Hide default input */
  cursor: pointer;
}

.file-input-container label {
  position: absolute;
  top: 10px;
  left: 0;
  color: var(--text-light);
  pointer-events: none;
  transition: 0.3s ease all;
  background-color: white;
  padding: 0 5px;
}

.file-input-container .custom-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  border: 0;
  border-bottom: 2px solid var(--border-lite);
  border-radius: 0;
  padding: 10px;
  display: flex;
  align-items: center;
}

.file-input-container .upload-icon {
  position: absolute;
  right: 10px;
  top: 20px;
  transform: translateY(-50%);
  pointer-events: none;
}

.file-input-container input[type="file"]:focus + .custom-input {
  border-color: #007bff; /* Change border color on focus/valid */
}

.file-input-container input[type="file"]:focus + .custom-input + label,
.file-input-container input[type="file"].has-value + .custom-input + label {
  top: -8px;
  font-size: 12px;
  color: #007bff; /* Change label color on focus/valid */
}

.file-input-container .selected-file {
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70%;
}

.date-custom {
  border: none;
  height: 7.3vh !important;
  border-bottom: 2px #e0e0e0 solid !important;
  border-radius: 0%;
}

.date-custom-label {
  /* top: -15px; */
  font-size: 12px;
  color: #6200ee;
}

.toast-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050;
}

.toast-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.PM_Dashbord_Select_disable{
background-color: hsl(0, 0%, 95%) !important;
    border: solid 0.3px #e6e6e6 !important;
    border-radius: 5px !important;
    padding: 5px 5px !important;
    /* height: 1px !important; */
}

.custom-nuber-input {
  border-radius: 0;
  border: none;
  border-bottom: 2px #e0e0e0 solid;
}

.custom-nuber-input:focus {
  border-bottom: 2px solid #6200ea !important;
  box-shadow: none !important;
}

/* new tab changes */
/* Tab button styles */
.tab-button {
  padding: 12px 20px;
  text-align: center;
  border: none;
  border-radius: 30px;
  background-color: #F8F1F6;
  cursor: pointer;
  font-size: 1rem;
  color: #555;
  transition: color 0.3s;
}

/* .pm-dashboard_tabs .nav-link.active {
  background-color: #DCDAF5 !important;
  color: #6750A4 !important;
  font-weight: 800 !important;
  border-radius: 30px !important;
  z-index: 1 !important;
  transition: color 0.3s !important;
} */
.pm-dashboard_tabs{
  background-color:#F8F1F6 ;
  border-radius: 30px;
}

.pm-dashboard_tabs .nav-link.active {
  background-color: #DCDAF5 !important;
  border-radius: 30px !important;
  border: transparent;
  /* border-bottom: 3px solid #6750a4 !important; */
  color: #6750a4 !important;
  font-weight: 700;
}
/* Tab indicator styles */
.tab-indicator {
  position: absolute !important;
  bottom: 0 !important;
  height: 100% !important;
  background-color: #DCDAF5 !important;
  border-radius: 30px !important;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out ;
}
li.nav-item {
  background-color: transparent !important;
}
.nav-tab .active{
  background-color: transparent;
}

.custom-file-input-wrapper {
  display: flex;
  align-items: center;
}

.custom-feedback-label {
  position: relative;
  cursor: pointer;
  display: inline-block;
  /* pointer-events: none; */
}

.custom-feedback-label input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 80%;
  height: 80%;
  cursor: pointer;
  pointer-events: none; /* Prevents hover effects like tooltips */
}

.custom-feedback-label:hover input[type="file"] {
  visibility: hidden; /* Hides hover effect */
}

.custom-feedback-span {
  display: inline-block;
  padding: 2.5px 2.5px;
  background-color: #f0f0f0;
  border: 0.01vh solid black;
  border-radius: 0.2vh;
  font-size: 2vh;
}

.file-count {
  width: 5vw;
  font-size: 2.1vh;
  margin-left: 0;
}

.PMD-feedback_li {
  /* margin-bottom: 2px; */
  font-size: 2vh;
  text-align: left;
  align-items: center;
  padding: 0;
  margin: 0;
}

.pmd-feedbackNote {
  /* color: lightcoral; */
  font-style: italic;
  color: red;
}

.pmd-partner-feedback_removeatt {
  cursor: pointer;
  color: red;
  margin-left: 2vw;
  font-size: 2vh;
}

.pmd-feedback_listmain {
  width: 50vw; 
  margin-left: 1vw;
  margin-top: 0
}

.pmd-custom-feedback-field,
.pmd-custom-feedback-field:focus {
  height: fit-content;
  border: 0.1vh solid #af94b5;
  border-left: 0.7vh solid #af94b5;
  border-radius: 1vh !important;
  font-size: 2vh;
  padding: 1vh !important;
  line-height: 2.5vh;
}
.pmd-custom-feedback-field::placeholder {
  font-size: 2vh;
}

.custom-modal .modal-body {
  max-height: 70vh !important;
  overflow: auto;
}

.pmd-feedback-field-color {
  color: #495057;
}

.pmd-custom-feedbackBtn {
  border: 0.1vw solid rgb(46, 45, 45);
  border-radius: 0 !important;
  width: 6vw !important;
  margin: 0;
  padding: 0.6vh;
  font-size: 2.3vh !important;
  margin-right: 1vh !important;
}