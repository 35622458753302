*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

header{
    position: absolute;
    top: -13px;
    left: 5rem;
    max-width: 1024px;
    width: 100%;
}

.main{
    background: url(../../../img//ChannelPartner/NewImg/shelf-plain.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: inherit;
    width: 100%;
    height: 85vh;
    display: flex;
    padding-top: 25px;
    padding-right: 90px;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    justify-content:flex-start;

}

.bookself{
    border-radius: 6px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: minmax(70px, auto);
    max-width: 790px;
    width: 100%;
    position: relative;
}

.name-plate{
    margin: -44px 0 0px 4px;
    position: absolute;
}
.name-plate .d-text{
    font-size: 12px;
    color: #f1f0ee;
    font-weight: 700;
    text-shadow: 0px 0px 0 rgb(163, 162, 159),
                 0px -1px  0 rgb(99, 98, 97),
                 -2px 2px 1px rgba(43, 42, 40, 0.6),
                 -2px 2px 1px rgba(31, 30, 29, 0.5),
                 0px 0px 1px rgba(12, 12, 12, 0.2);
}
/* .self h6{
    font-size: 0.7rem;
    padding: 4px 0 0 10px;
    color: #f3f3f3;
    font-family: "Arial Black", Gadget, sans-serif;
    text-shadow: 0px 0px 0 rgb(107,62,7),
                 0px 1px 0 rgb(55,10,0),
                 0px 2px  0 rgb(4,-41,0),
                 0px 3px 2px rgba(0,0,0,0.16),
                 0px 3px 1px rgba(0,0,0,0.5),
                 0px 0px 2px rgba(0,0,0,.2);
 } */
.colums1{
    grid-column: 1/2;
}
.colums2{
    grid-column: 2/5;
}
.colums3{
    grid-column: 5/6;
}
.colums4{
    grid-column: 6/7;
}
.colums5{
    grid-column: 7/8;
}
.colums6{
    grid-column: 1/2;
}
.colums7{
    grid-column: 2/3;
}
.colums13{
    grid-column: 1/3;
}

.bot {
    position: absolute;
    bottom: 80px;
    right: 350px;
}
.bot img{
    max-width: 100px;
    width: 100%;
    height: auto;
    border-radius: 3px 3px 10px black;
}

.floor-search {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 40px solid #ffffff;
    border-left: 45px solid transparent;
    border-right: 45px solid transparent;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    max-height: 30px;
    max-width: 550px;
    width: 100%;
}
.floor-search  input[type="search"]{
    border-bottom: 10px solid #ffffff;
    background-color: transparent;
    border: transparent;
    padding: 8px;
    width: 100%;
    height: 100%;
    font-style: italic;
}
.floor-search  input[type="search"]:focus-visible{
    outline: none;
}

/*====================self book css===================*/
.present-carousel{
    position: relative;
}
.guides-carousel{
    position: relative;
}
.book-wrap {
    column-gap: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: end;
    position: relative;
  }
.book-wrap-18 {
    column-gap: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: end;
  }
  .book-items {
    position: relative;
    cursor: default;
    padding: 0vh 0.5vw;
    padding-top: 0vh;
    padding-right: 18%;
    padding-bottom: 0vh;
    padding-left: 13%;
    margin: 0;
    display: grid;
    page-break-inside: avoid;
    break-inside: avoid;
    width: 3.5vw;
}
  
  .main-book-wrap {
    position: relative;
  }
  
  .book-cover {
    position: relative;
  }
  
  .book-cover .book-inside {
    position: absolute;
    width: 100%;
    height: 96%;
    top: 1%;
    left: 0.9vw;
    border: 0.2vh solid grey;
    border-radius: 1.1vh 1vh 1vh 0.15vh;
    background: white;
    box-shadow: 0.1vh 0.4vh 0.4vh -0.1vh #00000030, inset -0.3vh 0 0 grey, inset -0.5vh 0 0 #dbdbdb, inset -0.65vh 0 0 white, inset -0.8vh 0 0 #dbdbdb, inset -1.8vh 0 0 white, inset -1.8vh 0 0 #dbdbdb, inset -1.9vh 0 0 white, inset -2vh 0 0 #dbdbdb;
}
  
  .book-cover .book-image {
    line-height: 0;
    position: relative;
    border-radius: 1.1vh 1vh 1vh 0.15vh;
    box-shadow: 6px 6px 18px -2px rgba(0, 0, 0, 0.2), 24px 28px 40px -6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    transform: perspective(2000px) rotateY(0deg) translateX(0.4vw) scaleX(1);
    cursor: pointer;
}
  
  .book-image img {
    grid-row: 1 / -1;
    grid-column: 1;
    width: 100%;
    border-radius: 2px 6px 6px 2px;
  }
  
  .book-image:hover {
    transform: perspective(200px) rotateY(-20deg) translateX(2px) scaleX(0.94);
    transform-style: preserve-3d;
    box-shadow: 6px 6px 12px -1px rgba(0, 0, 0, 0.1), 20px 14px 16px -6px rgba(0, 0, 0, 0.1);
}
  
  .effect {
    position: absolute;
    width: 20px;
    height: 100%;
    margin-left: 16px;
    top: 0;
    border-left: 2px solid #00000010;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    transition: all 0.5s ease;
    z-index: 5;
  }
  
  .light {
    width: 90%;
    height: 100%;
    position: absolute;
    border-radius: 3px;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    top: 0;
    right: 0;
    opacity: 0.1;
    transition: all 0.5s ease;
    z-index: 4;
  }
  
  .book-image:hover .effect{
      margin-left: 14px;
  }
  
  .realvjy{
    width: 900px;
    padding: 20px;
    margin: 0 auto;
    font-family: sans-serif;
    text-align: center
  }

  /*====================self book css===================*/

  /*---------------- owl carousel -----------------*/

  /* .fa {
    color: white;
} */

  .owl-carousel .owl-nav button.owl-prev{
    background-color: var(--active);
    border: 1px solid var(--border);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    transition: 0.3s;
}

.owl-carousel .owl-nav button.owl-next {
    background-color: var(--active);
    border: 1px solid var(--border);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    transition: 0.3s;
}

.owl-prev {
    width: 16px;
    height: 100px;
    position: absolute;
    top: -30px;
    right: 30px;
}


.owl-next {
    width: 16px;
    height: 100px;
    position: absolute;
    top: -30px;
    right: 0;
}

.item{
    width: 55px;
}

.owl-stage {
    display: flex;
    align-items: self-end;
}

/*=========header menu==================*/

.primary-menu {
            position: relative;
            right: 0;
            top: 5%;
            left: 0px;
        }

        .primary-menu ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .primary-menu ul li {
            float: left;
            position: relative;
        }

        .primary-menu>ul>li>a {
            color: #002d62 !important;
            float: left;
            font-size: 1.2rem !important;
            padding: 0.8rem 6rem 0.8rem 1.8rem !important;
            font-weight: 700;
            text-decoration: none;
            width: 100%;
            border-bottom: .1rem solid rgba(102, 102, 102, .2);
        }

        .primary-menu>ul>li>a:hover,
        .primary-menu>ul>li>a:focus,
        .primary-menu>ul>li>a.active {
            color: #0d2481;
            text-decoration: none;
        }

        .dropdownmenu {
            position: relative;
        }

        .dropdownmenu:after {
            content: "\f105";
            font-family: 'FontAwesome';
            position: absolute;
            right: 35px;
            color: #000;
            font-size: 18px;
            top: 12px;
        }

        .dropdownmenu.active:after {
            display: none;
        }

        .dropdownmenu.active:before {
            content: "\f107";
            font-family: 'FontAwesome';
            position: absolute;
            right: 35px;
            color: #000;
            font-size: 18px;
            top: 12px;
        }

        li.dropdownmenu .sub-menu {
            background: #efefef !important;
            -webkit-box-shadow: inset 0 0.3rem 0.6rem 0 rgb(0 0 0 / 24%);
            box-shadow: inset 0 0.3rem 0.6rem 0 rgb(0 0 0 / 24%);
            display: none;
            padding: 0.3rem 0;
            left: 0;
        }

        li.dropdownmenu .sub-menu li {
            float: left;
            width: 100%;
            padding: 15px 0px 15px 25px;
            border-bottom: 0.1rem solid rgba(102, 102, 102, .2);
        }

        li.dropdownmenu .sub-menu a {
            font-size: 16px !important;
            font-weight: 700;
            color: #03145d;
            display: block;
            text-decoration: none;
        }


        #slide-menu {
            width: 100%;
        }

        #slide-menu {
            max-width: 350px;
        }

        .body-cover {
            background-color: rgba(255, 255, 255, 0.7);
            display: none;
            position: sticky;
            width: 100%;
            height: 100%;
            z-index: 999;
        }

        #slide-menu {
            height: 100vh;
            position: fixed;
            top: 0;
            right: -100%;
            background-color: rgba(255, 255, 255, 0.95);
            z-index: 9999;
            -webkit-transition: 0.2s ease-in-out;
            -moz-transition: 0.2s ease-in-out;
            -o-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;
            -webkit-transition-duration: 0.6s;
            -moz-transition-duration: 0.6s;
            -o-transition-duration: 0.6s;
            transition-duration: 0.6s;
            box-shadow: 0 0 5px rgb(0 0 0 / 50%);

        }

        #slide-menu.active {
            right: 0;
        }

        #slide-menu.active {
            min-height: 100vh;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .primary-menu {
            margin: 0 0 1rem;
        }

        .slide-menu-close {
            float: left;
            font-size: 1.5rem !important;
            cursor: pointer;
            margin: 1rem 1rem 0;
        }

        .skip-header ul {
            display: table;
        }

        .skip-header ul li {
            display: table-cell;
            text-align: center;
            width: 1%;
        }

        .skip-header ul li.search i:after {
            background-color: #ababab;
            content: '';
            left: 0;
            top: 16px;
            height: 0.9375rem;
            width: 1px;
            position: absolute;
        }

        .skip-header ul li.search i:after {
            top: 1px;
        }

        .skip-header ul li .skip-header-dd.active {
            display: block;
        }

        .skip-header ul li .skip-form {
            background-color: #dee2fe;
        }

        .primary-menu ul,
        .primary-menu ul li {
            width: 100%;
            float: left;
        }

        .skip-header ul li.menu-bars {
            cursor: pointer;
        }

        .skip-header ul li.menu-bars i {
            font-size: 18px;
        }

        li.dropdownmenu .sub-menu {
            position: relative;
            top: 0;
            width: 100%;
        }

        li.dropdownmenu.active .sub-menu {
            display: block;
        }

        .skip-header {
            position: absolute;
        }

        .primary-menu {
            position: absolute;
            right: -1rem;
            top: 10%;
        }

        .menu>li>a {
            padding-bottom: 1.875rem;
        }

        .skip-header ul {
            width: auto;
        }

        li.dropdownmenu .dd {
            position: relative;
            top: 0;
            width: 100%;
        }

        .skip-header ul {
            float: right;
        }

        .skip-header ul li {
            float: left;
        }

        .skip-header ul li a {
            padding: 0.625rem 1.25rem;
        }

        .skip-header ul li {
            padding: 0;
        }

        .primary-menu>ul>li>a svg {
            display: none;
        }


        .button_container {
            position: absolute;
            top: 0;
            right: 9%;
            width: 0    ;
            height: 53px;
            cursor: pointer;
            transition: opacity .5s ease;
            z-index: 9;
            margin-top: 10px;
        }


        .button_container span {
            width: 40%;
            top: 14px;
        }

        .button_container span:nth-of-type(2) {
            top: 21px;
        }

        .button_container span:nth-of-type(3) {
            top: 28px;
        }

        .button_container p {
            top: 10px;
        }

        .button_container.active .top {
            -webkit-transform: translateY(4px) translateX(0) rotate(45deg);
            transform: translateY(4px) translateX(0) rotate(45deg);
        }


        .slide-menu-close {
            width: 40px;
            height: 40px;
            cursor: pointer;
            transition: opacity .25s ease;
            z-index: 9;
            margin-top: 14px;
            background: transparent;
            border-radius: 50%;
            display: flex;
            align-items:center;
            justify-content: center;
        }


        .slide-menu-close span:nth-of-type(3) {
            top: 28px;
        }

        .slide-menu-close p {
            top: 10px;
        }


        header.shrink .button_container {
            top: 7px;
        }

        .button_container:hover {
            opacity: .7;
        }

        .button_container span {
            background: #03145d;
            height: 3px;
            width: 53%;
            position: absolute;
            top: 17px;
            left: 13px;
            -webkit-transition: all .35s ease;
            transition: all .35s ease;
        }

        .button_container p {
            position: absolute;
            left: -55px;
            top: 17px;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }

        .button_container.active .middle {
            opacity: 0;
            background: #FFF;
        }

        .button_container span:nth-of-type(2) {
            top: 26px;
            left: 16px;
        }

        .button_container.active .bottom {
            -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
            transform: translateY(-10px) translateX(0) rotate(-45deg);
            background: #FFF;
        }

        .button_container span:nth-of-type(3) {
            top: 35px;
            left: 13px;
        }

        .button_container.active .top {
            -webkit-transform: translateY(8px) translateX(0) rotate(45deg);
            transform: translateY(8px) translateX(0) rotate(45deg);
            background: #FFF;
        }

        .overlay {
            width: 100%;
            position: fixed;
            right: 0;
            top: 0;
            background-color: #093888;
            padding: 60px 0;
            margin: -100vh 0 0 0px;
            -webkit-transition: margin 0.5s ease-in-out;
            transition: margin 0.5s ease-in-out;
            height: 100vh;
            text-align: left;
            z-index: 1;
            overflow-y: auto;
            opacity: .9;
        }

        .overlay.open {
            margin: 0;
        }

        nav.overlay-menu {
            padding-top: 50px;
            margin-top: 30px;
        }

        .overlay-menu ul {
            text-align: center;
        }

        .overlay-menu ul li {
            padding: 10px 20px;
        }

        .overlay-menu ul li a {
            color: #fff;
            font-size: 30px;
            font-weight: 400;
            text-decoration: none;
            position: relative;
        }

        .overlay-menu ul li a:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            height: 2px;
            background: #FFF;
            -webkit-transition: .35s;
            transition: .35s;
        }

        .overlay-menu ul li a:hover:after,
        .overlay-menu ul li a:focus:after,
        .overlay-menu ul li a:active:after {
            width: 100%;
        }

        /*** end Menu ***/



        @media(max-width:768px) {
            .sidebar-contact {
                width: 100%;
                height: 100%;
                left: -100%;
            }

            .sidebar-contact .toggle {
                top: 50%;
                transform: translateY(-50%);
                transition: 0.5s;
            }

            .sidebar-contact.active .toggle {
                top: 0;
                right: 0;
                transform: translateY(0);
            }

            .scroll {
                width: 100%;
                height: 100%;
                overflow-y: auto;
            }

            .content {
                padding: 50px 50px;
            }
        }

        .brand img {
            width: 120px;
        }


/*----------------header menu ends-------------*/


.CP-head-search{
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
      
    }

    .CP-head-search input{

     height: 35px;
     text-indent: 25px;
     border: 2px solid #d6d4d4;


    }


    .CP-head-search input:focus{

     box-shadow: none;
     border: 2px solid #01A983;


    }

    .CP-head-search .fa-search{

     position: absolute;
     top: 10px;
     right: 16px;
     width: fit-content;

    }

    .CP-head-search button {
        position: absolute;
        top: 0;
        right: -1vw;
        height: 100%;
        width: 10vw;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: #01A983;
    }

    .CP-head-search button:hover{
     background: #01765b;

    }

.dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

@media all and (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
        width: 200px;
    }
	.dropdown-menu{
		position: relative;
	}
	.dropdown-menu .submenu{ 
		display: none;
		position: absolute;
		left:100%;
        top:0;
        min-width: 320px;
        width: 100%;
        height: max-content;
	}
	.dropdown-menu .submenu-left{ 
		right:100%;
        left:auto;
	}
	.arrow{ 
		position: relative;
	}
	.arrow::after{
        content: "";
		position: absolute;
        top: 8px;
        right: 5px;
        background: url(../../../img//ChannelPartner/NewImg/next.png);
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
	}

	.dropdown-menu > li:hover{ background-color: #f1f1f1 }
	.dropdown-menu > li:hover > .submenu{
		display: block;
	}
}
.dropdown-menu[data-bs-popper] {
    top: 100%;
    right: 0;
    left: unset;
    margin-top: 0.125rem;
}
/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {

.dropdown-menu .dropdown-menu{
		margin-left:0.7rem; margin-right:0.7rem; margin-bottom: .5rem;
}

}	
/* ============ small devices .end// ============ */

.left,.right{
    height: 95vh;
    width: 100%;
}

.btn-light:focus {
    color: #000;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

/*================= Left content ==============*/

.left{
    position: relative;
    width: 100%;
}
.left-btn{
    position: absolute;
    width: 100%;
    height: 310px;
    padding: 0 40px;
    box-sizing: border-box;
    display:flex;
    flex-direction: row;
    column-gap: 30px;
    align-items: center;
    border: 2px solid transparent;
    left: 0;
    bottom: 0;
}
.left-btn button{
    background-color: transparent;
    border: 0;
    padding: 40px;
    transition: 0.3s;
}
.left-btn button:hover{
    background-color: #0000000A;
    border: transparent;
    border-radius: 50px;
}

.feed{
    max-width: 130px;
    width: 100%;
    height: 150px;
    margin-top: 80px;
}
.knowlwdge{
    max-width: 170px;
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
}
.sol360{
    max-width: 120px;
    width: 100%;
    height: 150px;
    margin-bottom: 30px;
}


/*================= Left content ends==============*/


/*==================book button css =========*/
.right{
    position: relative;
}

/* .modal.fade.zoom:not(.show) .modal-dialog {
    transform: scale(0.2);
 }

 .modal-dialog {
    margin: 0.75rem auto;
} */

.self-area .books button {
    background-color: transparent;
    border: 0;
}

.self-area{
    /* background: url(../../../img//ChannelPartner/NewImg/bookshelf.png) no-repeat center; */
    background-size: cover;
    display: grid;
    grid-template-rows:repeat(2,1fr);
    grid-gap: 0.3em;
    padding: 1%;
    box-sizing: border-box;
    position: absolute;
    top: 1em;
    right: 1em;
    min-width: 350px;
    Max-width: 375px;
    width: 100%;
}

.bookshelf-up{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(120px, auto);
    column-gap: 0.4em;
}
.bookshelf-up button{
    max-width: 70px;
    width: 100%;
    max-height: 100px;
    height: 100%;
}
.bookshelf-down{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(120px, auto);
    column-gap: 0.3em;
}
.bookshelf-down button{
    max-width: 70px;
    width: 100%;
    max-height: 100px;
    height: 100%;
}

.vr{
    border: 2px solid transparent;
    position: absolute;
    bottom: 2em;
    left: 15em;
    max-width: 130px;
    width: 100%;
    height: 70px;
}
.vr button{
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: transparent;
    transition: 0.3s;
}
.vr button:hover{
    background-color: #0000000A;
    border-radius: 50px;
}

.lap-carosel{
    max-width: 185px;
    width: 100%;
    height: 100px;
    padding: 0 8px;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 36em;
}
.lap-carosel .item{
    width: 100%;
    height: 100px;
}
.lap-carosel .item img{
    width: 100%;
    height: 100%;
}

/* ---------------------------- Media query for homepage ----------------------------- */

@media screen and (min-width:1300px) and (max-width:1400px) {
    .knowlwdge {
        max-width: 200px;
        width: 100%;
        height: 150px;
        margin-bottom: 20px;
    }
}
@media screen and (min-width:1400px) and (max-width:1550px) {
    .knowlwdge {
        max-width: 230px;
        width: 100%;
        height: 150px;
        margin-bottom: 20px;
    }
    .sol360 {
        max-width: 120px;
        width: 100%;
        height: 188px;
        margin-bottom: -5px;
    }
    .feed {
        max-width: 143px;
        width: 100%;
        height: 150px;
        margin-top: 102px;
    }
    .vr {
        bottom: 2em;
        left: 15em;
        max-width: 150px;
        width: 100%;
        height: 81px;
    }
}
@media screen and (min-width:1550px) and (max-width:1750px) {
    .knowlwdge {
        max-width: 240px;
        width: 100%;
        height: 150px;
        margin-bottom: 100px;
    }
    .sol360 {
        max-width: 150px;
        width: 100%;
        height: 188px;
        margin-bottom: 44px;
    }
    .feed {
        max-width: 160px;
        width: 100%;
        height: 150px;
        margin-top: 80px;
    }
    .vr {
        bottom: 2em;
        left: 20em;
        max-width: 150px;
        width: 100%;
        height: 81px;
    }
}
@media screen and (min-width:1750px) and (max-width:2000px) {
    .vr {
        bottom: 5em;
        left: 24em;
        max-width: 150px;
        width: 100%;
        height: 95px;
    }
    .sol360 {
        max-width: 170px;
        width: 100%;
        height: 210px;
        margin-bottom: 204px;
    }
    .knowlwdge {
        max-width: 300px;
        width: 100%;
        height: 150px;
        margin-bottom: 180px;
    }
    .feed {
        max-width: 196px;
        width: 100%;
        height: 150px;
        margin-top: -41px;
    }
}

/*-----------------------------Media query ends-----------------------------------*/
/*==================book button css ends=========*/
/* .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0;
} */

.searchresults {
    height: 520px;
    max-height: 700px;
}

.capsules{
    padding-top: 14px;
}
.capsules, .doc-capsules {
    border: 1px solid #ccc;
    box-shadow: 2px 5px 5px grey;
    background-color: #fff;
    margin-bottom: 20px;
	background: linear-gradient(to right, #fff, #eee);
}
.row-padding-fix {
    padding-left: 15px;
    padding-right: 15px;
}

.main-container{
    display: grid;
    grid-template-columns: 3fr 3fr 3fr;
}


.capcontent{
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 10px;
    padding: 10px 12px;
    background-color: #4CBB7F;
}

/*========= accordian ===============*/
/* Custom style */
.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
    transform: scale(.7) !important;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  }
  
 :root {
	/* colors */
	 --body-bg: #fafafa;
	 --page-bg: #f5f5f5;
	 --dark-text: #2a2935;
	/* spacing */
	/* this is what defines the global scale */
	 --baseline: 12px;
	/* fonts */
	 --base-size: var(--baseline) * 1.2;
}


  .flipBtn {
    margin: 0 auto;
}
  .flipBtn button{
    background-color: #01765b;
    padding: 12px 16px;
    border: 0;
    color: #f1f1f1;
    font-size: 1.02rem;
}

/* .modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: unset;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
} */

/* .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */


.book-wrapper{
    position: relative;
    max-width: 850px;
    width: 100%;
    padding: 20px 12px;
    height: 100vh;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  h1 {
    font-size: 2.2rem;
  }
  
  .scene {
      max-width: 500px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
  }
  
  .book {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
  }
  
  .page {
    /* cursor: pointer; */
    position: absolute;
    color: black;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    transition: 1.5s transform;
    transform-style: preserve-3d;
    transform-origin: left center;
    font-size: 0.865rem;
  }
  
  .btn-light {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #f8f9fa;
  }
  
  .btn-save{
    background-color: #01765b;
    color: #f1f1f1;
    border-radius: 0;
  }
  .btn-cancel{
    border:1px solid #01765b;
    border-radius: 0;
  }
  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  
    backface-visibility: hidden;
    background: -webkit-gradient(linear, 0% 0%, 100% 100%, from(#FFFFFF), to(#CCCCCC));
    background: linear-gradient(to bottom right, #fff, #ccc);
  }
  .back {
    transform: rotateY(180deg);
  }
  
  .page.active {
    z-index: 1;
  }
  .page.flipped {
    transform: rotateY(-180deg);
  }
  .page.flipped:last-of-type {
    z-index: 1;
  }
  
  p {
    /* text-indent: 1em; */
    line-height: 1.7;
  }
  
  .qr {
    margin: 50px auto;
    max-width: 50%;
  }
  .qr img {
    display: block;
  }
  
  .flipBtn {
    margin: 0 auto;
  }
  .flipBtn button{
    background-color: #01765b;
    padding: 12px 16px;
    border: 0;
    color: #f1f1f1;
    font-size: 1.02rem;
    cursor: pointer;
  }
  
  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
  }
  
  
  /* iPads (portrait) ----------- */
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : portrait) {
    .scene {
      width: 90%;
      height: 90%;
      margin: 5%;
  }
  }

  /* .CP-head-book-image:hover {
  transform: scale(1.6);
  z-index: 999999 !important;
  cursor: pointer;
} */

/*============= guest landing page =================*/  