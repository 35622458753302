.card-body_us {
    flex: 0 0 auto;
    min-height: 1vh;
    padding: 0.8vh;
}

.badge-primary {
    color: #fff;
    margin-top: 1vh;
}

.card_us {
    margin-bottom: 1vh;
    box-shadow: 0 0.2vh 2.5vh 0.2vh rgba(52, 40, 104, .08);
}
.card-title-us{
    font-weight: 600;
}
.card_us {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0.2vh solid #e5e9f2;
    border-radius: 1vh;
}

.card-header_us:first-child {
    border-radius: calc(1vh - 0.2vh) calc(1vh - 0.2vh) 0 0;
}

.card-header_us {
    border-bottom-width: 0.2v;
    padding: 1vh 1vw;
    margin-bottom: 0;
    color: inherit;
    background-color: #fff;
    border-bottom: 0.2vh solid #e5e9f2;
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
}

.svg-inline--fa.fa-fw {
    width: 2.5vw;
}

.svg-inline--fa.fa-w-16 {
    width: 2vw;
}



.bg-soft-base {
    background-color: #e1f5f7;
}

.bg-soft-warning {
    background-color: #fff4e1;
}

.bg-soft-success {
    background-color: #d1f6f2;
}

.bg-soft-danger {
    background-color: #fedce0;
}

.bg-soft-info {
    background-color: #d7efff;
}

.search-form {
    width: 70%;
    margin: 0 auto;
    margin-top: 2vh;
}

.search-form input {
    height: 100%;
    background: transparent;
    border: 0;
    display: block;
    width: 100%;
    padding: 2vh;
    height: 100%;
    font-size: 1.5vh;
}

.search-form select {
    background: transparent;
    border: 0;
    padding: 2vh;
    height: 100%;
    font-size: 1.5vh;
}

.search-form select:focus {
    border: 0;
}

.search-form button {
    height: 100%;
    width: 100%;
    font-size: 1.5vh;
}

.search-form button svg {
    width: 3vh;
    height: 3vh;
}

.search-body {
    margin-bottom: 3vh;
}

.search-body .search-filters .filter-list {
    margin-bottom: 2vh;
}

.search-body .search-filters .filter-list .title {
    color: #3c4142;
    margin-bottom: 2vh;
}

.search-body .search-filters .filter-list .filter-text {
    color: #727686;
}

.search-body .search-result .result-header {
    margin-bottom: 4vh;
}

.search-body .search-result .result-header .records {
    color: #3c4142;
}

.search-body .search-result .result-header .result-actions {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-body .search-result .result-header .result-actions .result-sorting {
    display: flex;
    align-items: center;
}

.search-body .search-result .result-header .result-actions .result-sorting span {
    flex-shrink: 0;
    font-size: 1vh;
}

.search-body .search-result .result-header .result-actions .result-sorting select {
    color: #68CBD7;
}

.search-body .search-result .result-header .result-actions .result-sorting select option {
    color: #3c4142;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .search-body .search-filters {
        display: flex;
    }

    .search-body .search-filters .filter-list {
        margin-right: 2vw;
    }
}

.card-margin_us {
    margin-bottom: 3vh;
}

@media (min-width: 992px) {
    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
}

.card-margin_us {
    margin-bottom: 3vh;
}

.card_us {
    border: 0;
    box-shadow: 0 0 2vh 0 rgba(82, 63, 105, 0.1);
    -webkit-box-shadow: 0 0 2vh 0 rgba(82, 63, 105, 0.1);
    -moz-box-shadow: 0 0 2vh 0 rgba(82, 63, 105, 0.1);
    -ms-box-shadow: 0 0 2vh 0 rgba(82, 63, 105, 0.1);
}

.card_us {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #e6e4e9;
    border-radius: 8px;
}

.badge-primary {
    background-color: #7630EA;
    margin-left: 5px;
}

.bgc-h-secondary-l3:hover,
.bgc-secondary-l3 {
    background-color: #ebeff1 !important;
}

.h-4 {
    height: 2rem;
}

.w-4 {
    width: 2rem;
}

.d-zoom-1,
.d-zoom-2,
.d-zoom-3,
.dh-zoom-1,
.dh-zoom-2,
.dh-zoom-3 {
    transition: -webkit-transform 180ms;
    transition: transform 180ms;
    transition: transform 180ms, -webkit-transform 180ms;
}

.mr-25,
.mx-25 {
    margin-right: .75rem !important;
}

.p-25 {
    padding: .75rem !important;
}

.radius-1 {
    border-radius: .25rem !important;
}

[class*=bgc-h-] {
    transition: background-color .15s;
}

.text-default-d3 {
    color: #416578 !important;
    max-width: 90%;
}

.font-bolder,
.text-600 {
    font-weight: 600 !important;
}

.text-90 {
    font-size: .9em !important;
}

.bgc-h-secondary-l4:hover,
.bgc-secondary-l4 {
    background-color: #f2f4f6 !important;
}

.text-danger-m1 {
    color: #da3636 !important;
}

.text-green-m1 {
    color: #2c8d6a !important;
}

.text-95 {
    font-size: .95em !important;
}

.bg-success {
    background-color: #01a982 !important;
    font-size: 16px !important;
    color: #fff;
}
.my-scroll{
    min-height: 40vh;
   max-height: 40vh;
    overflow: scroll;
    overflow-x: hidden;
}

.user-role-popover {
    margin-left: 0.5rem;
}

.profile-loader-img {
    height: 10vh;
}

.homepage-userprofile-rows{
    width: 85vw !important;
    height: 70vh !important;
    margin-top: 5vh;
    margin-left: 2vw !important;
    color: rgb(21, 115, 71);
    padding: 0 !important;
}
