body{
	padding: 0px;
	margin: 0px;
}
.header{
	height: 72px;
	background-color: #000;
	width: 100%;
}
.logo{
	margin-top: 10px;
	padding-left: 15px;
}
.table {
  border: 1px solid black;
}
.etadays{
	font-size: 11px;
	padding-top: 5px;
}
.etadays0{
	font-size: 11px;
	color: #FFF;
	padding-top: 5px;
}
.additionaldays{
	color: red;
	font-size: 11px;
}
.blackball{
	height: 27px;
	width: 27px;
	border-radius: 50%;
	background-color: transparent;
	border: 2px solid black;
	text-align: center;
	color: black;
	display: flex;
    align-items: center;
    justify-content: center;
	font-size: 1vh
}
.greenball{
	height: 27px;
	width: 27px;
	border-radius: 50%;
	background-color: transparent;
	border: 2px solid #06ae51;
	text-align: center;
	color: #06ae51;
	display: flex;
    align-items: center;
    justify-content: center;
	font-size: 1vh
}
.greenballFill{
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color:  #06ae51;
	border: 2px solid #06ae51;
	text-align: center;
	color: #06ae51;
	display: flex;
    align-items: center;
    justify-content: center;
	font-size: 1vh
}
.greenballHalfFill{
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color:  #06ae51;
	border: 2px solid #06ae51;
	text-align: center;
	color: #06ae51;
	display: flex;
    align-items: center;
    justify-content: center;
	font-size: 1vh;
	border-radius: 50%;
	background: conic-gradient(transparent 0deg 180deg,#06ae51 180deg 360deg);
}

.redball{
	height: 27px;
	width: 27px;
	border-radius: 50%;
	background-color: transparent;
	border: 2px solid red;
	text-align: center;
	color: red;
	display: flex;
    align-items: center;
    justify-content: center;
	font-size: 1vh
}
.redballHalfFill{
	height: 27px;
	width: 27px;
	border-radius: 50%;
	background-color: transparent;
	border: 2px solid red;
	text-align: center;
	color: red;
	display: flex;
    align-items: center;
    justify-content: center;
	font-size: 1vh;
	border-radius: 50%;
	background: conic-gradient(transparent 0deg 180deg,red 180deg 360deg);
}

.orangeball{
	height: 27px;
	width: 27px;
	border-radius: 50%;
	background-color: transparent;
	border: 2px solid orange;
	text-align: center;
	color: orange;
	display: flex;
    align-items: center;
    justify-content: center;
	font-size: 1vh
}
.grayball{
	height: 27px;
	width: 27px;
	border-radius: 50%;
	background-color: transparent;
	border: 2px solid #CCCCCC;
	text-align: center;
	color: #CCCCCC;
	display: flex;
    align-items: center;
    justify-content: center;
}
.customCursor{
	cursor: pointer;
}
.table-bordered{
	border-color: #cccccc !important;
}
.legendgray{
	height: 15px;
	width: 15px;
	background-color: #ccc;
}
.legendgreen{
	height: 15px;
	width: 15px;
	background-color: #06ae51;
}
.legendorange{
	height: 15px;
	width: 15px;
	background-color:orange;
}
.legendred{
	height: 15px;
	width: 15px;
	background-color: red;
}
.legendblue{
	height: 15px;
	width: 15px;
	background-color: #01afef;
}

/* .e2eLabel > tr, td {
border: .1vh solid transparent !important;
} */

.e2eLabel, .e2eLabel td, .e2eLabel tr {
    border: none !important;
  }

.trafficLight{
    width: 3vw;
    height: 8vh;
    z-index: 10;
    padding-bottom: 5px;
}

.signText{
    position: absolute;
    top: 27%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 1.3vh;
}

.custom-progress-container {
    position: relative;
    width: 100%;
    height: 20px;
    background-color: transparent; 
    border: 1.5px solid #ddd; 
    border-radius: 15px; 
    overflow: hidden; 
	margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}
.progressBar {
	border-radius: 15px 15px 15px 15px; 
}
.custom-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgb(239, 115, 101) !important; 
    width: 0; 
    transition: width 0.3s; 
    border-radius: 15px 15px 15px 15px; 
}

.percentage-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; 
    font-size: small; 
    padding: 0 5px; 
    border-radius: 5px; 
}
.zero-percentage-text {
    position: absolute;
	margin-left: 0.5vh;
    color: black; 
    font-size: small; 
    padding: 0 5px; 
    border-radius: 5px; 
}
.date-text {
    text-align: center;
    font-size: smaller;
}

.progress-text{
    font-size: 1.2vh;
    margin-top: 2vh;
}
.progress-text1{
    font-size: 1.2vh;
}

.e2ePage .pagination {
    padding-left: 9vh;
	cursor: pointer;
  }

  .ganttTable td,  .ganttTable tr {
    border: 0.1vh solid transparent !important;
}

.e2eLogo{
    width: 7vw;
    height: 6vh;
}
.gradient-text {
	padding-left: 5px;
    padding-right: 5px;
	max-width: 90px;
	color: white;
	background: rgb(45, 43, 81);
	margin-bottom: 0.4vh !important
/* background: linear-gradient(90deg, rgb(45, 43, 81) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
  }
  .gradient-text-red {
	padding-left: 5px;
    padding-right: 5px;
	max-width: 90px;
	color: white;
	background: red;
  }
  .gradient-text-green {
	padding-left: 5px;
    padding-right: 5px;
	max-width: 90px;
	color: white;
	background: #06ae51;;
  }
/*   .truncate-customer {
	max-width: 3vw; 
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
  } */

  .truncate-customer {
	font-size: "2vh !important";
  }
  
  .truncate-customer h6 {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: "2vh !important";
  }
  

  .popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	border-radius: 10px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	padding: 20px;
	width: 400px;
	z-index: 1000;
}

.popup-header {
	padding: 10px;
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 16px;
	justify-content: space-between;
	color: white;
}

.close-btn {
	cursor: pointer;
	font-size: 18px;
	font-weight: bold;
}

.overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
}

/* Popup Headers */
.opportunity-popup .popup-header { background: #01afef; }
.invoice-popup .popup-header { background: #01afef; }
.deal-popup .popup-header { background: #d50000; }
.deploy-popup .popup-header { background: #d50000; }
    /* Disabled Tasks */
	.disabled {
		color: gray;
	}

	/* Message Box */
	.message-box {
		width: 100%;
		margin-top: 10px;
	}

	.message-box textarea {
		width: 80%;
		height: 60px;
		padding: 8px;
		border: 1px solid #ccc;
		border-radius: 5px;
		font-size: 14px;
	}

	.message-icon {
		margin-left: auto;
		cursor: pointer;
		font-size: 16px;
	}

	/* Buttons */
	.btn {
		padding: 10px 15px;
		margin: 5px;
		border: none;
		cursor: pointer;
		font-size: 14px;
		border-radius: 5px;
	}
.send-icon {
	cursor: pointer;
	font-size: 18px;
    margin-left: 235px;
    margin-top: -85px;
	}
	.messageText {
resize: none !important;
}
	.open-opportunity { background: #33ffcc; }
	.open-deal { background: #d50000; color: white; }
	.open-deploy { background: #d50000; color: white; }
	.arrowLogo{
		margin-top: 0px !important;
padding-left: 0px !important;}
.text{
position: absolute;
left: 55px;}
.clock{width: 16px;
margin-left: 67px;}
.taskdesign{
	border: 0.1vh solid transparent !important;
}
.taskdesc{
	background-color: rgb(200, 255, 249) !important;
}
.task-list-container {
	padding: 15px;
	border-radius: 10px;
	width: 80%;
	margin: 20px auto;
  }
  
  .task-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* background: #D4D4D4; */
	padding: 10px 15px;
	border-radius: 6px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	margin-bottom: 8px;
	transition: transform 0.2s;
  }
  .successrow{
	background: #CBFAEB;
  }
  .secondaryrow{
	background: #D4D4D4;
  }
  .dangerrow{
	background: #FFCDCD;
  }
  .warningrow{
	background: #FFDDA7;
  }
  .task-row:hover {
	transform: scale(1.02);
  }
  
  .task-title {
	flex: 4;
	font-weight: bold;
	/* color: #333; */
	color: white;
	padding-right: 5px;
  }
  
  .task-status {
	flex: 1;
	font-size: .8vw;
	padding: 5px 10px;
	border-radius: 15px;
	color: white;
	font-weight: bold;
	text-align: center;
  }
  
  .bg-successs { background: #28a745; } /* Green */
  .bg-secondarys { background: #D4D4D4 !important; } /* Gray */
  .bg-danger { background: #dc3545; } /* Red */
  .bg-warning { background: #ffc107; } /* Yellow */
  
  .task-hours {
	flex: 1;
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: .8vw;
	font-weight: bold;
	/* color: #555; */
	color: white;
	padding: 5px;
  }
  
  .time-icon {
	color: #007bff;
  }
  
  .task-email {
	flex: 1;
	font-size: .8vw;
	/* color: #666; */
	color: white;
	text-align: right;
	padding-left: 2px;
  }

  .ganttImage {
    width: 40px;
    height: auto;
    margin-left: 5px;
    margin-right: 15px;
    margin-top: 10px;
    background-color: white;
}

.progressBar.bg-danger {
	background-color: rgb(235 122 133) !important
}
.e2eLoading{
position: absolute;
    top: 36%;
    left: 50%;
}
.task {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 5px;
}

.task-text {
    font-weight: bold;
    min-width: 120px; 
    text-align: left;
}

.task span:last-child {
    flex-grow: 1;
    text-align: left;
}

.legendidentify{
	padding-right: 1vh;
}

.legendlabel{
	padding-right: 2vh;
}

.e2eHome{
	font-size: 2vh;
    color: blue !important;
    margin-top: 18vh;
    margin-left: 19vh;
}

  .accordion {
	border: 1px solid #ddd;
	border-radius: 5px;
	margin: 5px 0;
	padding: 5px;
  }
  
  .accordion-header {
	cursor: pointer;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: normal;
  }
  
  .accordion-body {
	display: flex;
	flex-direction: column;
	padding-left: 20px; 
  }
  
  .bullet-point {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
  }
  
  .bullet-point i {
	color: red;
	margin-right: 5px; 
  }
  
  .word-break {
	word-break: break-word;
  }
  
  .nested-accordion {
	padding: 5px;
	border: 1px solid #ddd;
    border-radius: 5px;
    margin: 5px 0;
  }
  
  .nested-accordion-header {
	cursor: pointer;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: normal;
  }
  
  .nested-accordion-body {
	padding-left: 15px;
  }
