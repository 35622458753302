.cp_header-main {
  margin: 0 auto;
  /* width: 100%; */
  height: 100vh;
 /*  min-height: 555px; */
  position: relative;
  background-image: url(../../../img/ChannelPartner/HeadSearch/NewImg/results-blue-bg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cp_header_col {
  height: 100%;
}

.cp_header-text {
  font-weight: bolder;
  display: flex;
  align-items: center;
  height: 100%;
}

.cp_header-text p {
  margin-bottom: 0;
  font-size: 1.5vw;
}

.cp_header-text1 {
  font-size: clamp(1rem, 1vw, 1.2rem);
}

.cp_header-profile {
  display: flex;
  justify-content: flex-end;
}

.cp_searchhead-main {
  align-items: center;
}

.cp_searchhead-head {
  height: 100%;
  width: 100%;
  max-height: 650px;
  max-width: 600px;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  background: url(../../../img/ChannelPartner/HeadSearch/01head-shelf-png.svg)
    no-repeat center;
  background-size: cover;
  color: white;
  font-size: clamp(0.6rem, 1vw, 0.9rem);
  z-index: 99;
}

.cp_searchhead_rack13 {
  background: url(../../../img/ChannelPartner/HeadSearch/best-pract.png)
    no-repeat center;
  background-size: cover !important;
  position: absolute;
  max-width: 166px;
  width: 100%;
  max-height: 33px;
  height: 100%;
  bottom: 135px;
  right: 99px;
  cursor: pointer;
}

.cp_searchhead_rack11 {
  background-size: cover !important;
  position: absolute;
  max-width: 125px;
  width: 100%;
  max-height: 37px;
  height: 100%;
  top: 105px;
  left: 100px;
  cursor: pointer;
}

.cp_searchhead_rack21 {
  background-size: cover !important;
  position: absolute;
  max-width: 130px;
  width: 100%;
  max-height: 30px;
  height: 100%;
  top: 111px;
  right: 170px;
  cursor: pointer;
}

.cp_searchhead_rack31 {
  background-size: cover !important;
  position: absolute;
  max-width: 150px;
  width: 100%;
  max-height: 30px;
  height: 100%;
  top: 220px;
  left: 38px;
  cursor: pointer;
}

.cp_searchhead_rack41 {
  background-size: cover !important;
  position: absolute;
  max-width: 145px;
  width: 100%;
  max-height: 40px;
  height: 100%;
  top: 273px;
  right: 98px;
  cursor: pointer;
}

.cp_searchhead_rack51 {
  background-size: cover !important;
  position: absolute;
  max-width: 140px;
  width: 100%;
  max-height: 35px;
  height: 100%;
  bottom: 252px;
  left: 80px;
  cursor: pointer;
}

.cp_searchhead_rack61 {
  background-size: cover !important;
  position: absolute;
  max-width: 166px;
  width: 100%;
  max-height: 33px;
  height: 100%;
  bottom: 135px;
  right: 99px;
  cursor: pointer;
}

.cp_searchhead_rack12 {
  background-size: cover !important;
  position: absolute;
  max-width: 110px;
  width: 100%;
  max-height: 35px;
  height: 100%;
  top: 83px;
  left: 125px;
  cursor: pointer;
}

.cp_searchhead_rack22 {
  background-size: cover !important;
  position: absolute;
  max-width: 110px;
  width: 100%;
  max-height: 35px;
  height: 100%;
  top: 80px;
  right: 195px;
  z-index: 1;
  cursor: pointer;
}

.cp_searchhead_rack32 {
  background-size: cover !important;
  position: absolute;
  max-width: 130px;
  width: 100%;
  max-height: 35px;
  height: 100%;
  top: 190px;
  left: 37px;
  cursor: pointer;
}
.cp_searchhead_rack42 {
  background-size: cover !important;
  position: absolute;
  max-width: 120px;
  width: 100%;
  max-height: 35px;
  height: 100%;
  top: 248px;
  right: 118px;
  cursor: pointer;
}
.cp_searchhead_rack52 {
  background-size: cover !important;
  position: absolute;
  max-width: 140px;
  width: 100%;
  max-height: 45px;
  height: 100%;
  bottom: 278px;
  left: 68px;
  cursor: pointer;
}

.cp_searchhead_rack62 {
  background-size: cover !important;
  position: absolute;
  max-width: 165px;
  width: 100%;
  max-height: 36px;
  height: 100%;
  bottom: 159px;
  right: 90px;
  z-index: 1;
  cursor: pointer;
}

.cp_searchhead_img-multimedia {
  background: url(../../../img/ChannelPartner/HeadSearch/multimedia.png)
    no-repeat center;
}

.cp_searchhead_img-guides {
  background: url(../../../img/ChannelPartner/HeadSearch/guides.png) no-repeat
    center;
}

.cp_searchhead_img-customerreference {
  background: url(../../../img/ChannelPartner/HeadSearch/cust-ref-case-stu.png)
    no-repeat center;
}

.cp_searchhead_img-announcements {
  background: url(../../../img/ChannelPartner/HeadSearch/single-book.png)
    no-repeat center;
}

.cp_searchhead_img-bestpratices {
  background: url(../../../img/ChannelPartner/HeadSearch/best-pract.png)
    no-repeat center;
}

.cp_searchhead_img-presentations {
  background: url(../../../img/ChannelPartner/HeadSearch/presentations.png)
    no-repeat center;
}

.cp_searchhead_img-successstory {
  background: url(../../../img/ChannelPartner/HeadSearch/success-story.png)
    no-repeat center;
}

.cp_searchhead_img-referencearchitecture {
  background: url(../../../img/ChannelPartner/HeadSearch/ref-arch.png) no-repeat
    center;
}

.cp_searchhead_img-competetiveinsights {
  background: url(../../../img/ChannelPartner/HeadSearch/comp-insight.png)
    no-repeat center;
}

.cp_searchhead_img-internaltrainings {
  background: url(../../../img/ChannelPartner/HeadSearch/internal-tran.png)
    no-repeat center;
}

.cp_searchhead_img-accelerators {
  background: url(../../../img/ChannelPartner/HeadSearch/accelarators.png)
    no-repeat center;
}

.cp_searchhead_img-marketinsights {
  background: url(../../../img/ChannelPartner/HeadSearch/market-insights.png)
    no-repeat center;
}

.cp_searchhead_img-greenlake {
  background: url(../../../img/ChannelPartner/HeadSearch/single-book.png)
    no-repeat center;
}

.cp_searchhead_img-github {
  background: url(../../../img/ChannelPartner/HeadSearch/single-book.png)
    no-repeat center;
}

.cp_searchhead_img-processdocument {
  background: url(../../../img/ChannelPartner/HeadSearch/single-book.png)
    no-repeat center;
}

.cp_searchhead_modal-index-section h2 {
  font-size: 1.5em;
  color: #333;
}

.cp_searchhead_modal-index-subsection h3 {
  font-size: 1.25vw;
  color: #555;
}

.cp_searchhead_modal ul {
  list-style-type: none;
  padding-left: 0;
}
.cp_searchhead_modal li {
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.cp_searchhead-book-wrap {
  column-gap: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: end;
  position: relative;
}

.cp_searchhead-book-items {
  position: relative;
  cursor: default;
  padding: 2px 4px;
  margin: 0;
  display: grid;
  break-inside: avoid;
  width: 50px;
}

.cp_searchhead-main-book-wrap {
  position: relative;
}

.cp_searchhead-book-cover {
  position: relative;
}

.cp_searchhead-book-cover .cp_searchhead-book-inside {
  position: absolute;
  width: 100%;
  height: 96%;
  top: 1%;
  left: 10px;
  border: 1px solid grey;
  border-radius: 2px 6px 6px 2px;
  background: white;
  box-shadow: 10px 40px 40px -10px #00000030, inset -2px 0 0 grey,
    inset -3px 0 0 #dbdbdb, inset -4px 0 0 white, inset -5px 0 0 #dbdbdb,
    inset -6px 0 0 white, inset -7px 0 0 #dbdbdb, inset -8px 0 0 white,
    inset -9px 0 0 #dbdbdb;
}

.cp_searchhead-book-cover .cp_searchhead-book-image {
  line-height: 0;
  position: relative;
  border-radius: 2px 6px 6px 2px;
  box-shadow: 6px 6px 18px -2px rgba(0, 0, 0, 0.2),
    24px 28px 40px -6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  transform: perspective(2000px) rotateY(0deg) translateX(6px) scaleX(1);
  cursor: pointer;
}

.cp_searchhead-book-image img {
  grid-row: 1 / -1;
  grid-column: 1;
  width: 100%;
  border-radius: 2px 6px 6px 2px;
}

.cp_searchhead-book-image:hover {
  transform: perspective(200px) rotateY(-20deg) translateX(2px) scaleX(0.94);
  transform-style: preserve-3d;
  box-shadow: 6px 6px 12px -1px rgba(0, 0, 0, 0.1),
    20px 14px 16px -6px rgba(0, 0, 0, 0.1);
}

.cp_searchhead-effect {
  position: absolute;
  width: 20px;
  height: 100%;
  margin-left: 16px;
  top: 0;
  border-left: 2px solid #00000010;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: all 0.5s ease;
  z-index: 5;
}

.cp_searchhead-light {
  width: 90%;
  height: 100%;
  position: absolute;
  border-radius: 3px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  top: 0;
  right: 0;
  opacity: 0.1;
  transition: all 0.5s ease;
  z-index: 4;
}

.cp_searchhead-book-image:hover .cp_searchhead-effect {
  margin-left: 14px;
}

/* .cp_searchhead-rack_3 {
  background-size: cover;
  position: absolute;
  max-width: 200px;
  width: 100%;
  max-height: 70px;
  height: 100%;
  top: 250px;
  right: 95px;
} */

/* .cp_searchhead-rack_4 {
  background-size: cover;
  position: absolute;
  max-width: 200px;
  width: 100%;
  max-height: 70px;
  height: 100%;
  top: 333px;
  left: 80px;
} */

/* .cp_searchhead-rack_5 {
  background-size: cover;
  position: absolute;
  max-width: 200px;
  width: 100%;
  max-height: 70px;
  height: 100%;
  top: 562px;
  left: 165px;
} */

/* .cp_searchhead-rack_6 {
  background-size: cover;
  position: absolute;
  max-width: 200px;
  width: 100%;
  max-height: 70px;
  height: 100%;
  top: 455px;
  right: 80px;
} */

.cp_searchhead-rackbook {
  background-color: transparent;
  height: 13vh;
  width: 2vw;
  /* margin-right: 4vw; */
  padding: 0 !important;
}

.CP-head-modal_close {
  float: right;
  width: 2em;
  height: 2em;
  padding: 0.25em 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
  opacity: 0.5;
  border-radius: 0.25rem;
  box-sizing: content-box;
  margin-left: 5%;
}
.CP-head-modal_close-row {
  position: absolute;
  margin-top: 8.3rem;
  right: -5%;
}
.CP-head-main-book-wrap {
  position: relative;
}
.CP-head-book-cover {
  position: relative;
}
.CP-head-artifact {
  width: 110%;
}

/* .CP-head-artifact2 {
  width: 110%;
} */
.CP-head-book-cover .CP-head-book-inside {
  position: absolute;
  width: 96%;
  height: 96%;
  top: 1%;
  left: 0.75vw;
  border: 1px solid grey;
  border-radius: 2px 6px 6px 2px;
  background: white;
  box-shadow: 10px 40px 40px -10px #00000030, inset -2px 0 0 grey,
    inset -3px 0 0 #dbdbdb, inset -4px 0 0 white, inset -5px 0 0 #dbdbdb,
    inset -6px 0 0 white, inset -7px 0 0 #dbdbdb, inset -8px 0 0 white,
    inset -9px 0 0 #dbdbdb;
}

.CP-head-book-cover .CP-head-book-image {
  line-height: 0;
  position: relative;
  border-radius: 2px 6px 6px 2px;
  box-shadow: 6px 6px 18px -2px rgba(0, 0, 0, 0.2),
    24px 28px 40px -6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  transform: perspective(2000px) rotateY(0deg) translateX(6px) scaleX(1);
  cursor: pointer;
}
.CP-head-book-image img {
  grid-row: 1 / -1;
  grid-column: 1;
  width: 100%;
  border-radius: 2px 6px 6px 2px;
}

.CP-head-book-image:hover {
  transform: perspective(200px) rotateY(-20deg) translateX(2px) scaleX(0.94);
  transform-style: preserve-3d;
  box-shadow: 6px 6px 12px -1px rgba(0, 0, 0, 0.1),
    20px 14px 16px -6px rgba(0, 0, 0, 0.1);
}
.CP-head-book-image:hover .CP-head-effect {
  margin-left: 14px;
}
.CP-head-effect {
  position: absolute;
  width: 20px;
  height: 100%;
  margin-left: 16px;
  top: 0;
  border-left: 2px solid #00000010;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: all 0.5s ease;
  z-index: 5;
}

.CP-head-light {
  width: 90%;
  height: 100%;
  position: absolute;
  border-radius: 3px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  top: 0;
  right: 0;
  opacity: 0.1;
  transition: all 0.5s ease;
  z-index: 4;
}

.CP-head-chatbot-container .rcw-close-widget-container > .rcw-launcher {
  right: 180px;
}

.rim1{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 0em;
  height: 0em;
  border: white .5em solid;
   background: #01a982; 
  /* background: green; */
  z-index: 9999999999999;
  }
  .rim1 {
  -webkit-animation: expand 2s linear infinite;
  }
  @keyframes expand {
    0% {
      top: 50%;
      left: 50%;
      width: 1em;
      height: 1em;
      border: white .25em solid;
      opacity: 1;
    }
    100% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3em;
      height: 3em;
      border: white .25em solid;
      opacity: 0;
    }
    }